/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Customer = {
    id?: number;
    logo?: string;
    name?: string;
    shortName?: string;
    slug?: string;
    description?: string;
    metadata?: Record<string, any>;
    products?: Array<string>;
    metrics?: Array<{
        name?: string;
        data?: string;
        description?: string;
    }>;
    location?: {
        latitude?: number;
        longitude?: number;
    };
    region?: Customer.region;
};

export namespace Customer {

    export enum region {
        NORTE = 'Norte',
        CENTRO = 'Centro',
        AML = 'AML',
        SUL = 'Sul',
    }


}

