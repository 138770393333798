import { Button, Card } from "flowbite-react";
import CardTitle from "../../atoms/card/card-title";
import "./team-card.scss";

type TeamCardOptions = {
  imageSrc: string;
  name: string;
  position: string;
  description: string;
  email: string;
  socials?: Array<{ social: string; link: string }>;
  index?: number;
};

export const TeamCard = ({
  imageSrc,
  name,
  position,
  description,
  email,
  socials = [],
  index = undefined,
}: TeamCardOptions) => {
  const expand = (event: any) => {
    const closestCard = event.currentTarget.closest(
      ".card.team-card"
    ) as Element;
    if (closestCard.classList.contains("expanded")) {
      event.currentTarget
        .closest(".card.team-card")
        .classList.remove("expanded");
    } else {
      event.currentTarget.closest(".card.team-card").classList.add("expanded");
    }
    event.currentTarget.blur();
  };

  return (
    <Card
      className="w-[250px] pb-2 card team-card relative
                     lg:pb-0 lg:w-[290px] lg:h-[605px]"
    >
      <div className="flex flex-col h-full w-full px-3 pt-3 pb-5">
        <div
          className="min-h-[173px] max-h-[173px] image w-full bg-cover lg:h-[202px] rounded-card mb-3"
          style={{ backgroundImage: `url("${imageSrc}")` }}
        ></div>
        <div className="body-container lg:mt-2 flex flex-col justify-center w-full px-2">
          <div className="title w-full mb-2">
            <CardTitle className="leading-6" text={name}></CardTitle>
          </div>
          <div className="mb-2">
            <span
              className="text-sm lg:text-base"
              dangerouslySetInnerHTML={{ __html: position || "" }}
            ></span>
          </div>
          <div className="description flex flex-grow ">
            <span className="text-sm">{description}</span>
          </div>
          <div className="email flex my-4">
            <svg className="svg-icon h-[24px] my-auto" viewBox="0 0 20 20">
              <path d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z"></path>
            </svg>
            <span className="ml-2 text-base my-auto">{email}</span>
          </div>
          <div
            className={`socials flex lg:flex-row my-2 ${
              socials.length <= 0 ? "hidden" : ""
            }`}
          ></div>
        </div>
      </div>
      <Button
        pill
        id="flip"
        color="white"
        className="chevron-button absolute right-4 bottom-5 bg-white rounded-full shadow-button w-[30px] h-[30px]
                   flex flex-col justify-center cursor-pointer border-primary-200 border-solid lg:hidden"
        onClick={expand}
      >
        <svg
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 8.66406L17.0623 0.601805"
            stroke="black"
            strokeLinecap="round"
          />
          <path
            d="M9.06226 8.66406L0.999998 0.601805"
            stroke="black"
            strokeLinecap="round"
          />
        </svg>
      </Button>

      {(() => {
        switch (index) {
          case 0:
            return (
              <img
                className="absolute -top-10 -left-10 -scale-x-100 max-w-[95px]"
                src="/assets/images/other/purple_dialog_baloon.png"
                alt="Baloon"
              />
            );
          case 1:
            return (
              <img
                className="absolute -top-7 -left-7 max-w-[80px]"
                src="/assets/images/other/empty_baloon.png"
                alt="Baloon"
              />
            );
          case 2:
            return (
              <img
                className="absolute -top-5 -right-5 max-w-[80px]"
                src="/assets/images/other/star_baloon.png"
                alt="Baloon"
              />
            );
          case 3:
            return (
              <img
                className="absolute -top-10 -right-5 max-w-[80px]"
                src="/assets/images/other/dialog_baloon_red.png"
                alt="Baloon"
              />
            );
          case 4:
            return (
              <img
                className="absolute -top-10 -left-10 max-w-[80px]"
                src="/assets/images/other/dialog_baloon_clear_round.png"
                alt="Baloon"
              />
            );
          case 5:
            return (
              <img
                className="absolute -top-5 -right-5 max-w-[80px]"
                src="/assets/images/other/dialog_baloon_clear_sharp.png"
                alt="Baloon"
              />
            );
          case 6:
            return (
              <img
                className="absolute top-[50%] -translate-y-[50%] -right-28
                            max-w-[200px] rotate-[55deg] -scale-x-100"
                src="/assets/images/other/rocketship.png"
                alt="Baloon"
              />
            );
          default:
            return null;
        }
      })()}
    </Card>
  );
};
