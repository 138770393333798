import {ImplementedProposal} from "../../../api";
import {ProposalCard} from "../../molecules/proposal-card/proposal-card";
import {ProposalModal} from "../../molecules/proposal-modal/proposal-modal";
import {create} from "zustand";

interface ImplementedProposalsState {
    openModal: boolean;
    activeProposal: ImplementedProposal | undefined;
    setOpenModal: (modalState: boolean) => void;
    setActiveProposal: (proposal: ImplementedProposal | undefined) => void;
}

const useState = create<ImplementedProposalsState>()((set) => ({
    openModal: false,
    activeProposal: undefined,
    setOpenModal: (modalState: boolean) =>
        set((state: ImplementedProposalsState) => ({
            openModal: modalState,
        })),
    setActiveProposal: (proposal: ImplementedProposal | undefined) =>
        set((state: ImplementedProposalsState) => ({
            activeProposal: proposal,
        })),
}));

export const ImplementedProposals = () => {
    const {openModal, setOpenModal, activeProposal, setActiveProposal} =
        useState();

  return (
    <div className="flex flex-col items-center mt-28">
      <div className="flex flex-col items-center gap-y-6">
        <span className="m-auto text-2.5xl font-semibold font-title text-center">
          Propostas Implementadas
        </span>
        <div className="relative flex flex-row">
          <img
            src="/assets/images/lines/spiral.svg"
            alt=""
            className="absolute -left-36 -top-16"
          />
          <img
            src="/assets/images/other/white_star.svg"
            alt=""
            className="absolute w-14 left-10 -top-[152px]"
          />
          <img
            src="/assets/images/other/white_star.svg"
            alt=""
            className="absolute w-9 -right-[106px] -top-10"
          />
          <span className="m-auto text-lg font-body text-center px-6 lg:px-0">
            Conhece as propostas criadas na MyPolis que já foram implementadas!
          </span>
                </div>
                <div className="flex w-[100vw] mt-8 px-3 overflow-x-scroll">
                    <div className="flex mx-auto">
                        <ProposalCard
                            proposal={{
                                title: "Festa da Juventude em Lagoa do Algarve",
                                body: "<b>Lagoa . 8, 9 e 10 de Julho 2018</b><br/><br/>O texto de preenchimento Lorem ipum é " +
                                    "utilizado por designers gráficos, programadores e impressoras com o objetivo de ocupar os " +
                                    "espaços de um site, um produto publicitário ou uma produção ditorial cujo texto final " +
                                    "ainda não está pronto. " +
                                    "Este expediente serve para ter uma idéia do produto final que em breve será impresso<br/>ou divulgado " +
                                    "por canais digitais.<br/>" +
                                    "Para ter um resultado que está de acordo com o resultado final, os designers gráficos, " +
                                    "designers ou tipógrafos informam o texto de Lorem ipsum em relação a dois aspectos " +
                                    "fundamentais, a saber, a legibilidade e os requisitos editoriais.",
                                technicalSheet: {
                                    proposalCreators: ["Lara Dias"],
                                    proposalImplementers: ["CM Lagoa"],
                                    school: "ESPAMOL",
                                    class: "6ºB",
                                    accountableTeacher: "Prof.ª Sílvia Paixão"
                                }
                            }}
                            activeProposal={activeProposal}
                            activeProposalHandler={setActiveProposal}
                            showMoreClickHandler={setOpenModal}
                        />
                        <ProposalCard
                            proposal={{
                                title: "Bananas",
                            }}
                            activeProposal={activeProposal}
                            activeProposalHandler={setActiveProposal}
                            showMoreClickHandler={setOpenModal}
                        />
                        <ProposalCard
                            proposal={{
                                title: "Batatas",
                            }}
                            activeProposal={activeProposal}
                            activeProposalHandler={setActiveProposal}
                            showMoreClickHandler={setOpenModal}
                        />
                        <ProposalCard
                            proposal={{
                                title: "Cenouras",
                            }}
                            activeProposal={activeProposal}
                            activeProposalHandler={setActiveProposal}
                            showMoreClickHandler={setOpenModal}
                        />
                        <ProposalCard
                            proposal={{
                                title: "Batatas",
                            }}
                            activeProposal={activeProposal}
                            activeProposalHandler={setActiveProposal}
                            showMoreClickHandler={setOpenModal}
                        />
                        <ProposalCard
                            proposal={{
                                title: "Batatas",
                            }}
                            activeProposal={activeProposal}
                            activeProposalHandler={setActiveProposal}
                            showMoreClickHandler={setOpenModal}
                        />
                        <ProposalModal
                            modalCloseHandler={setOpenModal}
                            openModal={openModal}
                            proposal={activeProposal as ImplementedProposal}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
