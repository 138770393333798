/* eslint-disable react-hooks/exhaustive-deps */
import mapboxgl, {
  AttributionControl,
  MapboxGeoJSONFeature,
  NavigationControl,
} from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { MutableRefObject, useEffect, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "./customer-map.scss";
import { FeatureCollection } from "geojson";
import { create } from "zustand";
import { Customer } from "../../../api";
import { CustomerMapPopup } from "../../molecules/customer-map-flip-card/customer-map-flip-card";
import { convertObjectToGeoJson } from "../../../utilities";
import { MockServices } from "../../../mock/MockServices";

type CustomerMapProps = {
  className?: string | undefined;
  title?: string;
  showTitleIcons?: boolean;
};

type GeoJsonFeature = {
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  id: string | number;
  layer: {
    id: string;
    type: string;
    source: string;
    filter: Array<any>;
    layout: object;
    paint: object;
  };
  properties: {
    cluster: boolean;
    cluster_id: number;
    point_count: number;
    point_count_abbreviated: number;
  };
  source: string;
  state: object;
  type: string;
};

type CustomGeoJSONType = MapboxGeoJSONFeature & {
  toJSON: () => GeoJsonFeature;
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

interface CustomerMapState {
  popupVisible: boolean;
  lat: number;
  lng: number;
  customersGeoJson: FeatureCollection | undefined;
  activeCustomer: Customer | undefined;
  zoom: number;
  territories: number;
  hidden: boolean;
  dataIsLoaded: boolean;
  previousClickedFeature: number | undefined;
  _setPreviousClickedFeature: (featureId: number) => void;
  setTerritories: (territories: number) => void;
  setPopupVisible: (modalState: boolean) => void;
  setActiveCustomer: (customer: Customer | undefined) => void;
  setLat: (lat: number) => void;
  setLng: (lng: number) => void;
  setZoom: (zoom: number) => void;
  setHidden: (hidden: boolean) => void;
  setCommunitiesGeoJson: (customersGeoJson: FeatureCollection) => void;
  setDataIsLoaded: (dataIsLoaded: boolean) => void;
}

const useState = create<CustomerMapState>()((set) => ({
  popupVisible: false,
  activeCustomer: undefined,
  lat: 39.69484,
  lng: -8.13031,
  zoom: 5.8,
  hidden: false,
  customersGeoJson: undefined,
  dataIsLoaded: false,
  previousClickedFeature: undefined,
  territories: 0,
  _setPreviousClickedFeature: (featureId: number) =>
    set((state: CustomerMapState) => ({
      previousClickedFeature: featureId,
    })),
  setTerritories: (territories) =>
    set((state: CustomerMapState) => ({ territories })),
  setPopupVisible: (modalState: boolean) =>
    set((state: CustomerMapState) => ({
      popupVisible: modalState,
    })),
  setActiveCustomer: (customer: Customer | undefined) =>
    set((state: CustomerMapState) => ({
      activeCustomer: customer,
    })),
  setLat: (lat: number) => set((state: CustomerMapState) => ({ lat: lat })),
  setLng: (lng: number) => set((state: CustomerMapState) => ({ lng: lng })),
  setZoom: (zoom: number) => set((state: CustomerMapState) => ({ zoom: zoom })),
  setHidden: (hidden: boolean) =>
    set((state: CustomerMapState) => ({ hidden: hidden })),
  setCommunitiesGeoJson: (customersGeoJson: FeatureCollection) =>
    set((state: CustomerMapState) => ({ customersGeoJson })),
  setDataIsLoaded: (dataIsLoaded: boolean) =>
    set((state: CustomerMapState) => ({ dataIsLoaded })),
}));

export const CustomerMap = ({
  className,
  title = "Territórios MyPolis",
  showTitleIcons = true,
}: CustomerMapProps) => {
  const mapContainer: any = useRef(null);
  const map: MutableRefObject<mapboxgl.Map> = useRef<mapboxgl.Map>(
    null
  ) as MutableRefObject<mapboxgl.Map>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    lng,
    lat,
    zoom,
    popupVisible,
    setPopupVisible,
    activeCustomer,
    setActiveCustomer,
    previousClickedFeature,
    _setPreviousClickedFeature,
    territories,
    setTerritories,
    hidden,
    setHidden,
    customersGeoJson,
    setCommunitiesGeoJson,
    dataIsLoaded,
    setDataIsLoaded,
  } = useState();

  const previousClickedFeatureRef = useRef(previousClickedFeature);
  const setPreviousClickedFeature = (feature: number) => {
    previousClickedFeatureRef.current = feature;
    _setPreviousClickedFeature(feature);
  };

  const fetchCommunities = () => {
    return MockServices.CustomersMockService.getCustomers().then(
      (customers): void => {
        setCommunitiesGeoJson(
          convertObjectToGeoJson(customers) as FeatureCollection
        );
        setTerritories(customers.length);
        setDataIsLoaded(true);
      }
    );
  };

  const navigation = new NavigationControl({
    showCompass: false,
    showZoom: true,
  });

  const attribution = new AttributionControl();

  const initializeMap = () => {
    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lng, lat],
        zoom: zoom,
        attributionControl: false,
        cooperativeGestures: true,
        antialias: true,
        worldview: "PT",
      });

      map.current.addControl(navigation, "bottom-right");
      map.current.addControl(attribution, "bottom-left");

      map.current.loadImage(
        "/assets/images/other/unclustered-point.png",
        (error, image) => {
          if (error) {
            setHidden(true);
            console.error(error);
            return;
          }

          map.current.addImage("unclustered-point", image as ImageBitmap);
        }
      );

      map.current.loadImage(
        "/assets/images/other/clustered-point.png",
        (error, image) => {
          if (error) {
            setHidden(true);
            console.error(error);
            return;
          }

          map.current.addImage("clustered-point", image as ImageBitmap);
        }
      );

      map.current.loadImage(
        "/assets/images/other/unclustered-selected-point.png",
        (error, image) => {
          if (error) {
            setHidden(true);
            console.error(error);
            return;
          }

          map.current.addImage(
            "unclustered-selected-point",
            image as ImageBitmap
          );
        }
      );
    } catch (e) {
      console.error(e);
      setHidden(true);
    }
  };

  function loadCustomerLayer() {
    try {
      if (!map.current.isStyleLoaded()) return;

      console.log("Customer Feature Collection: ", customersGeoJson);
      const customerSource = map.current.getSource("customers");

      if (customerSource) {
        map.current.removeLayer("clustered-customers");
        map.current.removeLayer("unclustered-customers");
        map.current.removeLayer("unclustered-selected-customers");
        map.current.removeSource("customers");
      }

      if (customersGeoJson) {
        console.log(customersGeoJson);
        map.current.addSource("customers", {
          type: "geojson",
          data: customersGeoJson,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 15, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.current.addLayer({
          id: "unclustered-customers",
          type: "symbol",
          source: "customers",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-allow-overlap": true,
            "icon-anchor": "bottom",
            // "icon-image": [
            //   "case",
            //   ["boolean", ["feature-state", "clicked"], true],
            //   ["image", "unclustered-selected-point"],
            //   ["image", "unclustered-point"],
            // ],
            "icon-image": "unclustered-point",
            "icon-size": 0.5,
          },
          paint: {
            "icon-opacity": [
              "case",
              ["boolean", ["feature-state", "clicked"], false],
              0,
              1,
            ],
          },
        });

        map.current.addLayer({
          id: "unclustered-selected-customers",
          type: "symbol",
          source: "customers",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-allow-overlap": true,
            "icon-anchor": "bottom",
            // "icon-image": [
            //   "case",
            //   ["boolean", ["feature-state", "clicked"], true],
            //   ["image", "unclustered-selected-point"],
            //   ["image", "unclustered-point"],
            // ],
            "icon-image": "unclustered-selected-point",
            "icon-size": 0.55,
          },
          paint: {
            "icon-opacity": [
              "case",
              ["boolean", ["feature-state", "clicked"], false],
              1,
              0,
            ],
          },
        });

        map.current.addLayer({
          id: "clustered-customers",
          type: "symbol",
          source: "customers",
          filter: ["has", "point_count"],
          layout: {
            "icon-allow-overlap": true,
            "icon-anchor": "bottom",
            "icon-image": "clustered-point",
            "icon-size": 0.55,
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 14,
            "text-anchor": "bottom",
            "text-offset": [0, -2.4],
          },
        });
      }
    } catch (e) {
      console.error(e);
      setHidden(true);
    }
  }

  useEffect(() => {
    if (!map.current) {
      initializeMap();
    } // initialize map only once

    map.current.on("load", () => {
      fetchCommunities();
      const roadLayers = map.current
        .getStyle()
        .layers.filter((layer: any) => layer.id.includes("road"));

      for (const layer of roadLayers) {
        map.current.removeLayer(layer.id);
      }

      // map.current.setFeatureState({ source: "customers" }, { clicked: false });

      map.current.on("click", "clustered-customers", (e) => {
        const bbox: [[number, number], [number, number]] = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5],
        ];
        // Find features intersecting the bounding box.
        const clustered = map.current.queryRenderedFeatures(bbox, {
          layers: ["clustered-customers"],
        });

        if (clustered.length > 0) {
          const selectedCluster = (clustered[0] as CustomGeoJSONType).toJSON();
          map.current.flyTo({
            zoom: map.current.getZoom() + 1,
            center: [
              selectedCluster.geometry.coordinates[0] -
                5 / (map.current.getZoom() + 1),
              selectedCluster.geometry.coordinates[1],
            ],
          });
        }
      });

      map.current.on("click", "unclustered-customers", (e: any) => {
        if (e.features === undefined) return;
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates;
        const customer = JSON.parse(e.features[0].properties.data);
        const clickedFeature = e.features[0].id;

        console.log("Previous: ", previousClickedFeatureRef.current);

        if (
          previousClickedFeatureRef?.current &&
          previousClickedFeatureRef.current !== 0
        ) {
          // Revert the style for the previously clicked feature
          map.current.setFeatureState(
            {
              source: "customers",
              id: previousClickedFeatureRef.current,
            },
            { clicked: false }
          );
        }

        map.current.setFeatureState(
          { source: "customers", id: clickedFeature },
          { clicked: true }
        );

        map.current.flyTo({
          speed: 1,
          center: [coordinates[0] - 5 / map.current.getZoom(), coordinates[1]],
        });

        setActiveCustomer(customer);
        setPopupVisible(true);
        setPreviousClickedFeature(clickedFeature);
      });
    });
  }, []);

  useEffect(() => {
    if (!dataIsLoaded) return;
    loadCustomerLayer();
  }, [dataIsLoaded]);

  return (
    <div className={`mt-24 ${className}`}>
      <div className="flex flex-col items-center gap-y-6 w-full mb-12">
        <span className="m-auto text-2.5xl font-semibold font-title text-center">
          {title}
        </span>
        <div className="relative flex flex-row">
          <img
            src="/assets/images/other/yellow_balloon.png"
            alt=""
            className={`${
              showTitleIcons ? "" : "hidden"
            } absolute rotate-6 left-4 -top-36 lg:-left-16 lg:-top-24`}
          />
          <img
            src="/assets/images/other/white_star.svg"
            alt=""
            className={`${
              showTitleIcons ? "" : "hidden"
            } absolute w-9 right-4 -top-24 lg:-right-[106px] lg:-top-10`}
          />
          <img
            src="/assets/images/lines/spiral.svg"
            alt=""
            className={`${
              showTitleIcons ? "" : "hidden"
            } hidden lg:block absolute w-36 -rotate-[35deg] -right-64 -top-32`}
          />
          <span className="m-auto text-lg font-body text-center px-6 lg:px-0">
            Já estamos em {territories} territórios! Explora o mapa para
            descobrir mais.
          </span>
        </div>
      </div>
      <div
        className={`w-full h-[600px] relative px-6 customer-map ${
          hidden && "hidden"
        }`}
      >
        <div className="absolute h-full w-screen left-1/2 -translate-x-1/2">
          <div
            ref={mapContainer}
            className="map-container relative h-full w-full"
          />
        </div>
        {activeCustomer ? (
          <CustomerMapPopup
            className="ml-8"
            visible={popupVisible}
            customer={activeCustomer as Customer}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
