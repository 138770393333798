import {TeamCard} from "../../molecules/team-card/team-card";

type TeamCardType = {
    imageSrc: string,
    name: string,
    position: string,
    description: string,
    email: string,
    socials?: Array<{ social: string, link: string }>
    index?: number;
};

function randomNumberInRange(min: number, max: number): number {
    // 👇️ get number between min (inclusive) and max (inclusive)
    console.log(Math.floor(Math.random() * (max - min + 1)) + min);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const teamList: Array<TeamCardType> = [
    {
        imageSrc: "/assets/images/team/bernardo.png",
        name: "Bernardo Gonçalves",
        position: "O nosso <i>founder</i> e <b>CEO</b>",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/virginia.png",
        name: "Virginia",
        position: "Designer",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/marina.png",
        name: "Marina",
        position: "Gestora de Projeto",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/bruno.png",
        name: "Bruno",
        position: "Full-stack Engineer",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/soraia.png",
        name: "Soraia",
        position: "Gestora de Projeto",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/patricia.png",
        name: "Patrícia",
        position: "Designer / Ilustradora",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/joao.png",
        name: "João",
        position: "Lead Front-end Developer",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/pedro.png",
        name: "Pedro",
        position: "<b>CTO</b>",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/solange.png",
        name: "Solange",
        position: "Gestora de Projeto",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/ilpo.png",
        name: "Ilpo",
        position: "Gestor de Projeto",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/lucia.png",
        name: "Lúcia",
        position: "Game Developer",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/mariana.png",
        name: "Mariana",
        position: "Coordenadora Pedagógica",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/cristian.png",
        name: "Cristian",
        position: "Diretor de Operações",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    },
    {
        imageSrc: "/assets/images/team/francisca.png",
        name: "Francisca",
        position: "Gestora de Projeto",
        description: "A princesa da Mypolis, corre pelas terras do sul, de escola em escola, transformando cada aluno " +
            "e aluna com a sua alegria e boa disposição, munida pela sua inteligência e espírito de missão cívica. " +
            "Nos tempos livres leva as plateias ao rubro enquanto atriz na vida e no Teatro Boa Esperança Ahhhh!! " +
            "E tem 80000 mil cães e gatos aos quais dá muito amor e carinho.",
        email: "bernardo@mypolis.eu",
        index: randomNumberInRange(0, 10)
    }
];

export const TeamList = () => {
    return (
        <div className="flex flex-col mt-24 lg:mt-36 z-10">
            <div className="flex mb-8 w-full lg:mb-16">
                <div className="mx-auto bg-white px-12 lg:px-20 py-7 rounded-2xl relative">
                    <img
                        src="/assets/images/lines/spiral_down.png"
                        alt="Baloon"
                        className="absolute -top-20 lg:-top-24 -left-20 max-w-[125px] rotate-2 lg:-left-14 lg:max-w-[155px]"
                    />
                    <img
                        src="/assets/images/other/yellow_baloon_straight.png"
                        alt="Baloon"
                        className="absolute -top-10 right-10 max-w-[80px] lg:-top-14 lg:right-12 lg:max-w-[110px]"
                    />
                    <span className="text-2xl sm:text-2.5xl font-semibold font-title text-secondary-500"
                    >A Nossa<br className="block lg:hidden"/> Equipa</span>
                    <img
                        src="/assets/images/lines/curve.png"
                        alt="Curve"
                        className="absolute top-2 left-3 lg:left-10 max-w-[35px] rotate-[5deg]"
                    />

                    <img
                        src="/assets/images/lines/curve.png"
                        alt="Curve"
                        className="absolute bottom-2 right-9 max-w-[35px] rotate-[175deg] block lg:hidden"
                    />
                </div>
            </div>

            <div className="flex flex-row flex-wrap justify-around mx-auto z-10 relative">
                {teamList.map((el, i) => {
                    return (
                        <TeamCard
                            key={i}
                            imageSrc={el.imageSrc}
                            name={el.name}
                            position={el.position}
                            description={el.description}
                            email={el.email}
                            index={el.index}
                        ></TeamCard>
                    )
                })}
            </div>
        </div>
    )
}