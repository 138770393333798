import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import "./navbar-link.scss";

type NavbarLinkProps = {
  to?: string;
  containerClasses?: string;
  linkClasses?: string;
  disabled?: boolean;
};

export const NavbarLink = ({
  to = "#",
  containerClasses = "",
  linkClasses = "",
  children = "",
  disabled = false,
}: PropsWithChildren<NavbarLinkProps>) => (
  <NavLink
    onClick={(e) => disabled && e.preventDefault()}
    id={`nav-link-${to.replace("/", "")}`}
    className={`h-[60px] block hover:underline hover:bg-gray-100 ${linkClasses}`}
    to={to}
  >
    <li
      className={`flex items-center justify-center font-medium px-6 h-[60px] w-auto lg:font-normal ${containerClasses}`}
    >
      {children}
    </li>
  </NavLink>
);
