import { Product } from "../../api";

export const Products: Product[] = [
  {
    id: 4,
    banner: 'T"Yk84vv+5',
    name: "Quae eius reprehenderit et animi nesciunt animi et doloremque architecto. Hic modi dolores et quia sed et est. Vero soluta et veritatis. Eligendi enim quasi natus. Repellendus quae corporis ex earum odio eos dolor dolores dolore.",
    description: "et quisquam cumque",
    objectives: "Cumque sapiente et velit quia et qui.",
    target_audience:
      "Maiores magnam dolore sed eius eos doloribus possimus et in. Illo enim dolorem ut numquam non placeat voluptas qui ut. Architecto temporibus quo dolorem. Fugit ea culpa maxime et repellat excepturi nulla.",
    results:
      "Molestias quidem voluptas nemo voluptas ipsa. Architecto et ipsa unde. Rerum quia velit nulla nulla expedita earum maxime.\n \rPariatur sunt perferendis. Beatae est aperiam suscipit est facilis cum nemo. Debitis natus repellendus explicabo qui excepturi. Numquam ut aut a omnis. Pariatur id provident occaecati consectetur nostrum.\n \rDignissimos sunt iusto aut autem nam. Aspernatur minus voluptatibus pariatur voluptas voluptatem autem sed id. Hic ut ut dicta odio occaecati autem error. Odit ea sed deserunt dicta sint velit quod. Quia quaerat odio voluptatem earum.",
  },
  {
    id: 116,
    banner: "&I5-]a_M65",
    name: "Possimus sit ut molestias iste illum. Est nemo quaerat accusantium maxime sunt aut. Excepturi et veniam est.",
    description:
      "Perspiciatis officiis dolores exercitationem quia beatae corrupti enim illum.\nQui eius eos aliquam autem voluptatum non sit.\nUt consectetur ad itaque voluptatem aspernatur.\nId neque maiores voluptatem laudantium quaerat odio.\nVoluptatem beatae nulla ut repellat cumque sed et quis veritatis.",
    objectives: "et",
    target_audience:
      "Explicabo id quis a et et qui ipsam sit excepturi.\nNulla sed qui non qui sequi.\nUt voluptate odio quae maxime quidem quae totam.",
    results:
      "Reiciendis debitis qui ut ea rem. Esse qui est enim fugiat explicabo autem ut. Reiciendis assumenda sit consequatur.\n \rVoluptates maxime voluptas ea ratione aut maiores architecto. Modi optio ut dolorem consequatur dolor est autem velit. Tenetur omnis quis eaque voluptas nihil voluptatem sed. Nesciunt quam aut est nam. Vel ut at dolorem et.\n \rAb recusandae dolores voluptatum laborum. Dolores et quis sapiente eos iusto. Repudiandae iste corrupti et. Quisquam maxime illo beatae consectetur.",
  },
  {
    id: 80,
    banner: "W5hI6kdF#=",
    name: "Aspernatur harum dolorum corporis temporibus placeat sapiente.",
    description: "nulla",
    objectives:
      "Itaque voluptas enim quia corporis. Deleniti similique velit. Est facilis sapiente.\n \rQuas consequatur velit debitis non rerum sit nemo sed. Sed consequuntur eum sapiente tempore et odio. Ipsum modi magni omnis nam a magni nemo libero praesentium. Sunt et numquam. Totam rerum maxime qui consequatur dolorem. Qui dolores enim animi veniam.\n \rEsse occaecati aliquam molestias non dolorem nulla ea quia. Ex quas qui deleniti voluptas quisquam atque. Ut aut consequatur voluptatem nihil magnam consequatur nulla. Et iste dolorum. Repellendus voluptatibus dolorem dolorum consequatur modi ut qui et eum. Adipisci illum unde quas unde autem sapiente.",
    target_audience:
      "Voluptatem ad error. Quidem iure pariatur qui quidem ut itaque.",
    results: "ut",
  },
  {
    id: 134,
    banner: "=8FY!U#ka!",
    name: "Sed assumenda quis eligendi quisquam ratione aliquid nobis earum dolore.\nIure delectus quam dolores sapiente architecto.\nId id qui accusantium.\nQuidem occaecati quibusdam aliquid labore.\nAccusamus incidunt cumque dolorem sapiente.",
    description:
      "Aut labore non rerum. Officia aliquid perferendis excepturi quo rerum a laudantium quo quo. A laborum voluptatibus consectetur amet porro quia voluptate voluptatibus.\n \rRerum aspernatur dolor molestias sit quo. Accusamus ut cum. Et eum assumenda dicta qui non perferendis veritatis laborum minima. Quis sed est ipsa dicta et molestiae illo eum voluptatem.\n \rAliquam et accusamus molestiae dignissimos voluptas delectus at optio. Laborum ipsa expedita beatae sed ut accusamus. Autem aliquid consequatur porro laudantium esse facilis voluptate.",
    objectives: "Quae cumque minima eum occaecati est provident rem ex est.",
    target_audience:
      "Laboriosam asperiores enim dolores consequatur quibusdam ut. Et nihil dolore rerum qui. Id voluptates autem quia. Ut harum ea delectus non dolorem impedit vero quam aut.\n \rSed ut repudiandae dolorem similique. Id exercitationem harum ex consequuntur nostrum nisi a sit. Aut ducimus quia nihil.\n \rOdio perspiciatis laudantium error voluptatibus. Quas modi error tempora. Ea quas tempora deleniti corporis. Et explicabo voluptate atque ab id voluptatem incidunt quo. Qui dolor aut. Omnis sed et.",
    results:
      "Dolores facilis molestiae et voluptate voluptas qui doloribus.\nIllo tenetur perspiciatis illo autem in quasi.\nVoluptas ipsum harum voluptatibus sit.\nCulpa accusantium rerum aut.",
  },
  {
    id: 42,
    banner: "u]n\\y}ZMG!",
    name: "At aut ut optio explicabo quae nostrum tempora expedita et.",
    description:
      "Ratione nisi blanditiis fuga voluptatibus aliquam rerum delectus modi voluptas.",
    objectives:
      "Hic ad cum illo ratione ducimus atque. Quia repellendus cumque distinctio nisi commodi placeat.",
    target_audience:
      "Eveniet corporis fugit sit animi nam a voluptas alias.\nLabore rerum doloremque.",
    results: "voluptas",
  },
  {
    id: 70,
    banner: "Xdi[3i`@K}",
    name: "Quia at vitae cupiditate nihil et et natus. Voluptas laboriosam saepe quia non quis suscipit cupiditate aperiam consequatur. Est nemo quaerat ipsa fugiat. Ducimus officiis aliquam cumque odit a cum nihil.",
    description:
      "Eos facilis et sequi enim autem sint.\nIure in repudiandae facilis optio minima iste excepturi reprehenderit.",
    objectives:
      "Quod at cumque doloremque qui. Molestias accusamus explicabo fuga. Est illum iste sed qui qui et quidem.",
    target_audience:
      "Necessitatibus provident suscipit repellendus sunt nostrum.\nLaboriosam velit aut praesentium.",
    results: "atque",
  },
  {
    id: 124,
    banner: "4]KW)QtU&}",
    name: "Blanditiis non impedit qui omnis id sed. Voluptatibus reiciendis libero enim. Quaerat et ipsa voluptas.",
    description:
      "Aliquam nisi et. Sunt ipsa fugiat omnis tempore error. Tempore voluptatem et aliquam voluptates ipsum libero voluptatum ut error. Iusto labore aspernatur porro.\n \rDignissimos architecto voluptatem et. Ea dolorem odio eum ipsam. Cumque accusamus saepe quia earum culpa ut. Molestias inventore voluptatem repudiandae ipsam excepturi qui aut ea. Maiores porro animi et sunt dolor labore soluta. Quia ducimus iusto similique quam est.\n \rImpedit nostrum pariatur vitae velit corrupti reprehenderit. Praesentium quasi et pariatur quia ad. Dignissimos suscipit et asperiores. Eos vel earum ab qui. Libero id a. Dolorem labore maiores.",
    objectives:
      "Explicabo incidunt rerum rerum. Voluptates similique eligendi quas nobis eligendi quo ut. Eos sapiente asperiores. Voluptas aliquam ex odio aut consequatur tempora perferendis ullam.",
    target_audience:
      "Molestias voluptatum et tempore deserunt consequatur illo omnis rem. Unde aperiam est nostrum unde inventore illo pariatur perferendis. Aspernatur laudantium doloribus et qui aut consequuntur. Vel voluptatem doloribus. Eaque sit nulla ipsa officiis. Doloremque velit ullam impedit.",
    results:
      "Laboriosam beatae aut beatae provident unde ab nobis. At suscipit totam dignissimos maiores. Est et odio. Exercitationem qui veniam eaque occaecati placeat. Est alias qui ipsum.",
  },
  {
    id: 59,
    banner: "@5jC132SjO",
    name: "nisi",
    description:
      "Quia est aut asperiores sequi vel aut ullam.\nReiciendis est enim qui voluptatem.\nImpedit sequi fuga quia aspernatur quasi quos architecto commodi laudantium.\nDolorum dicta rem.",
    objectives:
      "Asperiores rerum et ea vel expedita beatae magnam. Consequatur facere facilis illum consequatur voluptatem beatae cum. Commodi suscipit aperiam nobis sed saepe assumenda in nihil aut.",
    target_audience:
      "Non dolor illo qui dolorem blanditiis voluptas. Autem fugit aut voluptas.",
    results: "Possimus ea mollitia.",
  },
  {
    id: 11,
    banner: "#EzY]muCun",
    name: "Expedita porro accusantium rerum nemo id ut odio. Quia earum et asperiores. Non libero impedit nemo similique recusandae quas voluptas voluptate accusamus.",
    description: "quas",
    objectives:
      "Earum omnis molestiae impedit iste. Quidem consequatur quia id ad cupiditate. Explicabo qui ut nobis eos possimus. Ut labore voluptatem rerum minus.\n \rOfficiis et et autem esse. Est magni atque dicta iusto harum. Sint laborum dolorum consequatur sit sed sint. Rerum reiciendis reprehenderit nobis. Occaecati animi nihil.\n \rSunt error ut earum totam dolores nisi. Et ipsa ullam totam distinctio ex eveniet. Qui sed sunt qui reprehenderit beatae ex id. Molestias consectetur ipsa libero. Commodi ab aut.",
    target_audience:
      "Qui ut autem vel aut. Qui provident quidem magni quibusdam deleniti ut veritatis. Et optio dolores vero dolorem id maxime earum quos.\n \rEt velit quia quod quidem voluptate magnam dicta. Laborum quae eveniet tempora quaerat aperiam sint quia. Aperiam nobis hic.\n \rVeniam sed laboriosam non cupiditate voluptatibus. Tempore eaque sit voluptate. Nulla in dicta animi maiores dolores nostrum qui ea. Nam saepe cumque voluptates et quia enim illo.",
    results:
      "Et in omnis quae. Temporibus id consequatur aut qui maiores quo ullam consequatur. Quia rem sed tenetur fuga. Suscipit provident omnis sit. Eligendi ullam consequuntur quidem quia.\n \rQuos occaecati voluptatem tempore fuga est rem vel. Qui dolores et distinctio. Perferendis in labore aut voluptatum ut hic velit aut maiores. Accusamus est velit esse non similique. Soluta fuga quam.\n \rSint est aliquid quam eius. Pariatur amet facere qui et eos impedit sit veniam corporis. Molestias necessitatibus enim quia. Rerum ut repellat.",
  },
  {
    id: 131,
    banner: "8UtADdzuSv",
    name: "Quo voluptas laudantium enim et velit aspernatur molestiae sed porro.\nVoluptatem inventore quasi ea quo culpa.",
    description:
      "Possimus officiis non earum iusto id aliquam occaecati aut. Facere voluptas saepe corrupti aspernatur sapiente aut debitis est nihil.",
    objectives:
      "Voluptatem esse error neque quaerat voluptatibus atque.\nNon expedita repellendus.",
    target_audience:
      "Et quia fugiat officiis. Amet sed minus. Et culpa eos ut iure dolore architecto omnis enim non. Voluptas soluta magni a occaecati dolores iste architecto. Ratione eos nihil sequi aut sed doloribus dolores harum beatae. Placeat pariatur quae quia harum rem.",
    results:
      "Sed quisquam accusamus fuga quam omnis. Non iusto ratione non omnis ut aliquam tenetur. Sit consequatur ad adipisci quaerat nesciunt et illo perferendis explicabo. Et quibusdam expedita et assumenda reprehenderit voluptatem laboriosam nemo accusamus.\n \rAnimi nam eligendi et reprehenderit qui nihil. Sit recusandae aspernatur esse est quia quae illum ab qui. Ad asperiores quia. Dolores repellendus vero optio earum rem. Quis magnam debitis ab soluta porro.\n \rNulla et minus. Ut voluptas fugiat et eius. Vitae ut pariatur aperiam reiciendis.",
  },
];
