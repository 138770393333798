import {
  CancelablePromise,
  CommunitiesService,
  Community,
  Customer,
  CustomersService,
  Metric,
  MetricsService,
  Post,
  PostsService,
  Product,
  ProductsService,
} from "../api";

import { Customers, Products } from "./data";

type StaticMethods<T> = {
  [K in keyof T]: T[K] extends Function ? K : never;
}[keyof T];

type StaticService<T> = {
  [K in StaticMethods<T>]: T[K];
};

const metrics: Metric[] = [];

const posts: Post[] = [];

const Communities: Community[] = [];

export namespace MockServices {
  export const CustomersMockService: StaticService<typeof CustomersService> = {
    /**
     * Retrieve all customers
     * @returns Customer Successful response
     * @throws ApiError
     */
    getCustomers: (): CancelablePromise<Customer[]> => {
      return new CancelablePromise((resolve) => resolve(Customers));
    },

    /**
     * Create a new customer
     * @param requestBody Customer data
     * @returns Customer Customer created successfully
     * @throws ApiError
     */
    createCustomer: (requestBody: Customer): CancelablePromise<Customer> => {
      return new CancelablePromise((resolve) => resolve({}));
    },

    /**
     * Retrieve a specific customer by ID
     * @param customerId ID of the customer
     * @returns Customer Successful response
     * @throws ApiError
     */
    getCustomerById: (customerId: string): CancelablePromise<Customer> => {
      return new CancelablePromise((resolve) => {
        resolve(
          Customers.find(
            (customer) => customer.id === parseInt(customerId, 10)
          ) ?? {}
        );
      });
    },

    /**
     * Update a customer by ID
     * @param customerId ID of the customer
     * @param requestBody Customer data
     * @returns Customer Customer updated successfully
     * @throws ApiError
     */
    updateCustomerById: (
      customerId: string,
      requestBody: Customer
    ): CancelablePromise<Customer> => {
      return new CancelablePromise((resolve) =>
        resolve(
          Customers.find(
            (customer) => customer.id === parseInt(customerId, 10)
          ) ?? {}
        )
      );
    },

    /**
     * Delete a customer by ID
     * @param customerId ID of the customer
     * @returns void
     * @throws ApiError
     */
    deleteCustomerById: (customerId: string): CancelablePromise<void> => {
      return new CancelablePromise((resolve) => resolve());
    },
  };

  export const CommunitiesMockService: StaticService<
    typeof CommunitiesService
  > = {
    /**
     * Retrieve all customers
     * @returns Customer Successful response
     * @throws ApiError
     */
    getCommunities: (): CancelablePromise<Community[]> => {
      return new CancelablePromise((resolve) => resolve(Communities));
    },

    /**
     * Create a new customer
     * @param requestBody Customer data
     * @returns Customer Customer created successfully
     * @throws ApiError
     */
    createCommunity: (requestBody: Community): CancelablePromise<Community> => {
      return new CancelablePromise((resolve) => resolve({}));
    },

    /**
     * Retrieve a specific customer by ID
     * @param customerId ID of the customer
     * @returns Customer Successful response
     * @throws ApiError
     */
    getCommunityById: (communityId: string): CancelablePromise<Community> => {
      return new CancelablePromise((resolve) => {
        resolve(
          Communities.find((community) => community.id === communityId) ?? {}
        );
      });
    },

    /**
     * Update a customer by ID
     * @param customerId ID of the customer
     * @param requestBody Customer data
     * @returns Customer Customer updated successfully
     * @throws ApiError
     */
    updateCommunityById: (
      communityId: string,
      requestBody: Community
    ): CancelablePromise<Community> => {
      return new CancelablePromise((resolve) =>
        resolve(
          Communities.find((community) => community.id === communityId) ?? {}
        )
      );
    },

    /**
     * Delete a customer by ID
     * @param customerId ID of the customer
     * @returns void
     * @throws ApiError
     */
    deleteCommunityById: (communityId: string): CancelablePromise<void> => {
      return new CancelablePromise((resolve) => resolve());
    },
  };

  export const ProductsMockService: StaticService<typeof ProductsService> = {
    /**
     * Retrieve all products
     * @returns Product Successful response
     * @throws ApiError
     */
    getProducts: (): CancelablePromise<Array<Product>> => {
      return new CancelablePromise((resolve) => resolve(Products));
    },

    /**
     * Create a new product
     * @param requestBody Product data
     * @returns Product Product created successfully
     * @throws ApiError
     */
    createProduct: (requestBody: Customer): CancelablePromise<Product> => {
      return new CancelablePromise((resolve) => resolve({}));
    },

    /**
     * Retrieve a specific product by ID
     * @param productId ID of the product
     * @returns Product Successful response
     * @throws ApiError
     */
    getProductById: (productId: string): CancelablePromise<Product> => {
      return new CancelablePromise((resolve) => {
        resolve(
          Products.find((product) => product.id === parseInt(productId, 10)) ??
            {}
        );
      });
    },

    /**
     * Update a product by ID
     * @param productId ID of the product
     * @param requestBody Product data
     * @returns Product Product updated successfully
     * @throws ApiError
     */
    updateProductById: (
      productId: string,
      requestBody: Product
    ): CancelablePromise<Product> => {
      return new CancelablePromise((resolve) =>
        resolve(
          Products.find((product) => product.id === parseInt(productId, 10)) ??
            {}
        )
      );
    },

    /**
     * Delete a product by ID
     * @param productId ID of the product
     * @returns void
     * @throws ApiError
     */
    deleteProductById: (productId: string): CancelablePromise<void> => {
      return new CancelablePromise((resolve) => resolve());
    },
  };

  export const PostsMockService: StaticService<PostsService> = {
    /**
     * Retrieve all posts
     * @returns Post Successful response
     * @throws ApiError
     */
    getPosts: (): CancelablePromise<Array<Post>> => {
      return new CancelablePromise((resolve) => resolve(posts));
    },

    /**
     * Create a new post
     * @param requestBody Post data
     * @returns Post Post created successfully
     * @throws ApiError
     */
    createPost: (requestBody: Post): CancelablePromise<Post> => {
      return new CancelablePromise((resolve) => resolve({}));
    },

    /**
     * Retrieve a specific post by ID
     * @param postId ID of the post
     * @returns Post Successful response
     * @throws ApiError
     */
    getPostById: (postId: string): CancelablePromise<Post> => {
      return new CancelablePromise((resolve) => {
        resolve(posts.find((post) => post.id === parseInt(postId, 10)) ?? {});
      });
    },

    /**
     * Update a post by ID
     * @param postId ID of the post
     * @param requestBody Post data
     * @returns Post Post updated successfully
     * @throws ApiError
     */
    updatePostById: (
      postId: string,
      requestBody: Post
    ): CancelablePromise<Post> => {
      return new CancelablePromise((resolve) =>
        resolve(posts.find((post) => post.id === parseInt(postId, 10)) ?? {})
      );
    },

    /**
     * Delete a post by ID
     * @param postId ID of the post
     * @returns void
     * @throws ApiError
     */
    deletePostById: (postId: string): CancelablePromise<void> => {
      return new CancelablePromise((resolve) => resolve());
    },
  };
  export const MetricsMockService: StaticService<typeof MetricsService> = {
    /**
     * Retrieve all metrics
     * @returns Metric Successful response
     * @throws ApiError
     */
    getMetrics: (): CancelablePromise<Array<Metric>> => {
      return new CancelablePromise((resolve) => resolve(metrics));
    },

    /**
     * Create a new metric
     * @param requestBody Metric data
     * @returns Metric Metric created successfully
     * @throws ApiError
     */
    createMetric: (requestBody: Metric): CancelablePromise<Metric> => {
      return new CancelablePromise((resolve) => resolve({}));
    },

    /**
     * Retrieve a specific metric by ID
     * @param metricId ID of the metric
     * @returns Metric Successful response
     * @throws ApiError
     */
    getMetricById: (metricId: string): CancelablePromise<Metric> => {
      return new CancelablePromise((resolve) =>
        resolve(
          metrics.find((metric) => metric.id === parseInt(metricId, 10)) ?? {}
        )
      );
    },

    /**
     * Update a metric by ID
     * @param metricId ID of the metric
     * @param requestBody Metric data
     * @returns Metric Metric updated successfully
     * @throws ApiError
     */
    updateMetricById: (
      metricId: string,
      requestBody: Metric
    ): CancelablePromise<Metric> => {
      return new CancelablePromise((resolve) =>
        resolve(
          metrics.find((metric) => metric.id === parseInt(metricId, 10)) ?? {}
        )
      );
    },

    /**
     * Delete a metric by ID
     * @param metricId ID of the metric
     * @returns void
     * @throws ApiError
     */
    deleteMetricById: (metricId: string): CancelablePromise<void> => {
      return new CancelablePromise((resolve) => resolve());
    },
  };
}
