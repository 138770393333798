import { Button } from "flowbite-react";
import { PropsWithChildren } from "react";
import "./flippable-card.scss";

type FlippableCardOptions = {
  buttons: boolean;
  className?: string;
  imgSrc: string;
  cardTitle: string;
};

export const FlippableCard = ({
  imgSrc,
  buttons = true,
  cardTitle,
  className,
  children,
}: PropsWithChildren<FlippableCardOptions>) => {
  const flipCard = (event: any) => {
    event.currentTarget.closest(".flippable-card").classList.add("flipped");
    event.currentTarget.blur();
  };

  const unflipCard = (event: any) => {
    event.currentTarget.closest(".flippable-card").classList.remove("flipped");
    event.currentTarget.blur();
  };

  return (
    <div
      className={
        (className ?? "") + " relative h-96 max-w-full min-w-[330px] " +
          "lg:h-80 lg:max-w-[280px] lg:min-w-[280px] flippable-card rounded-card"
      }
    >
      <div className="card-front absolute w-full h-full rounded-card overflow-hidden">
        <div className="relative w-full h-full">
          <img className="absolute bottom-0 w-full" src={imgSrc} alt="" />
          <div className="absolute text-xl font-title top-4 left-5 w-56">
            {cardTitle}
          </div>
        </div>
        {buttons ? (
          <Button
            pill
            id="flip"
            color="white"
            className="absolute right-5 bottom-5 bg-white rounded-full shadow-button w-12 h-12 flex flex-col justify-center cursor-pointer border-primary-200 border-solid"
            onClick={flipCard}
          >
            <svg
              className="w-8 h-8 m-auto text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </Button>
        ) : (
          ""
        )}
      </div>
      <div className="absolute card-back border-0 w-full h-full bg-white rounded-card overflow-hidden px-7 py-7 text-sm">
        {children}
        {buttons ? (
          <Button
            pill
            id="flip"
            color="white"
            className="absolute right-5 bottom-5 bg-white rounded-full shadow-button w-12 h-12 flex flex-col justify-center cursor-pointer"
            onClick={unflipCard}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
