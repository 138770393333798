type CardVideoOptions = {
  src: string;
};

const CardVideo = ({ src }: CardVideoOptions) => {
  const video = document.createElement("video");
  video.className = "rounded-3xl";
  video.src = src;
  return <video src={src} className="rounded-3xl"></video>;
};

export default CardVideo;
