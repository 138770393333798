import {Button, TextInput, Textarea} from "flowbite-react";
import {create} from "zustand";
import { NavLink } from "react-router-dom";

import "./contact-us.scss";

enum Category {
    POLITICIAN = "politician",
    TEACHER = "teacher",
    YOUTH = "youth",
    OTHER = "other"
}

interface ContactFormState {
    selectedCategory: Category
    changeCategory: (to: Category) => void
}

const useState = create<ContactFormState>()((set) => ({
    selectedCategory: Category.POLITICIAN,
    changeCategory: (to) => set((state: ContactFormState) => ({selectedCategory: to})),
}))

export const ContactUs = ({className = ""}) => {

    const selectedCategory = useState((state: ContactFormState) => state.selectedCategory)
    const changeCategory = useState((state: ContactFormState) => state.changeCategory)

    if (className === "municipalities") {
        changeCategory(Category.POLITICIAN);
    } else if (className === "schools") {
        changeCategory(Category.TEACHER);
    }

    return (
        <div className="contact-us flex w-full relative -mt-10 lg:mt-0 z-0">
            <div className={"hidden flex-col items-center w-full "
                + (className === "landing" ? "landing" : "")}>
                <div className="flex -mt-10 w-full justify-center">
                    <img
                        src="/assets/images/other/dialog_baloons.png"
                        alt="Baloons"
                        className="max-w-[1050px] mx-auto hidden lg:block"
                    />

                    <img
                        src="/assets/images/other/baloons_spread_mobile.png"
                        alt="Baloons"
                        className="max-w-[550px] ml-28 block lg:hidden"
                    />
                </div>

                <span className="text-[1.75rem] font-semibold font-title text-secondary-500 mb-7 text-center">
                  Entra em contacto connosco!
                </span>
                <div
                    className="flex flex-col lg:flex-row items-start justify-center w-full mb-8 gap-x-10 forms relative">
                    <div className="flex flex-col items-center justify-center w-full lg:w-2/5 gap-y-4 relative z-10">
                        <span>Seleciona uma das opções:</span>
                        <Button
                            pill
                            color={selectedCategory === "politician" ? "primary" : "white"}
                            className="w-64 h-10 font-title font-normal"
                            onClick={() => changeCategory(Category.POLITICIAN)}
                        >
                            Representantes Políticos
                        </Button>
                        <div className="flex flex-row gap-x-4">
                            <Button pill color={selectedCategory === "teacher" ? "primary" : "white"}
                                    className="w-44 font-title font-normal"
                                    onClick={() => changeCategory(Category.TEACHER)}>
                                Professores
                            </Button>
                            <Button pill color={selectedCategory === "youth" ? "primary" : "white"}
                                    className="w-44 font-title font-normal"
                                    onClick={() => changeCategory(Category.YOUTH)}>
                                Jovens
                            </Button>
                        </div>
                        <Button pill color={selectedCategory === "other" ? "primary" : "white"}
                                className="w-44 font-title font-normal"
                                onClick={() => changeCategory(Category.OTHER)}>
                            Outro
                        </Button>
                    </div>
                    <div className="flex flex-col items-end w-full lg:w-1/2 mt-4 lg:mt-0 px-6 lg:px-0">
                        <div className="flex flex-col justify-end w-full gap-y-4 relative">
                            <form id="contact-form" action=""
                                  className={`flex flex-col gap-y-4 z-10 ${selectedCategory}`}>
                                <div className="w-full">
                                    <TextInput
                                        id="full-name"
                                        placeholder="Nome"
                                        required
                                        type="text"
                                        color="input"
                                        name="fullName"
                                    />
                                </div>
                                <div className="w-full politician">
                                    <TextInput
                                        id="politician-role"
                                        placeholder="Cargo"
                                        required
                                        type="text"
                                        color="input"
                                        name="politician-role"
                                    />
                                </div>
                                <div className="w-full teacher">
                                    <TextInput
                                        name="schoolOrGrouping"
                                        id="school-or-grouping"
                                        placeholder="Escola/Agrupamento"
                                        required
                                        type="text"
                                        color="input"
                                    />
                                </div>
                                <div className="w-full politician">
                                    <TextInput
                                        name="cityCounsel"
                                        id="city-counsel"
                                        placeholder="Autarquia"
                                        required
                                        type="text"
                                        color="input"
                                    />
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        required
                                        type="email"
                                        color="input"
                                    />
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        name="phone"
                                        id="phone"
                                        placeholder="Contacto Telefónico"
                                        required
                                        type="text"
                                        color="input"
                                    />
                                </div>
                                <div className="w-full" id="textarea">
                                    <Textarea
                                        name="message"
                                        id="message"
                                        placeholder="Mensagem"
                                        required
                                        rows={10}
                                        color="input"
                                        className="resize-none"
                                    />
                                </div>
                            </form>

                            <Button pill color="primary" className="self-end w-32">
                                Enviar
                            </Button>
                        </div>
                    </div>

                    <img
                        src="/assets/images/other/spring.png"
                        alt=""
                        className="absolute left-0 bottom-1 lg:bottom-auto lg:top-[300px] max-w-[143px] z-0"
                    />

                    <img
                        src="/assets/images/other/white_star.svg"
                        alt=""
                        className="absolute left-[350px] -bottom-16 lg:bottom-auto lg:top-[350px] max-w-[38px] z-0"
                    />

                    <img
                        src="/assets/images/other/rocketship.png"
                        alt=""
                        className="absolute -rotate-[180deg] -scale-y-100 left-[200px] top-16 lg:top-[400px]
                        max-w-[320px] z-0"
                    />
                </div>
            </div>

            <div className={"hidden flex-col items-center w-full relative "
                + (className === "municipalities" ? "municipalities" : "")}>
                <div className="flex -mt-10 w-full justify-center">
                    <img
                        src="/assets/images/other/dialog_baloons_spread.png"
                        alt="Baloons"
                        className="hidden lg:block max-w-[1050px] mx-auto"
                    />

                    <img
                        src="/assets/images/other/baloons_mobile.png"
                        alt="Baloons"
                        className="block lg:hidden max-w-[875px] mt-0 -ml-24"
                    />
                </div>

                <div className="flex flex-col absolute top-[215px] left-1/2 -translate-x-1/2 w-full">
                    <span className="text-[1.75rem] font-semibold font-title text-secondary-500 text-center">
                      Pronto para transformar a<br/>sua comunidade?
                    </span>
                    <p className="text-base text-center text-secondary-500 pt-7 pb-5">O processo é simples:</p>
                    <div className="flex flex-col">
                        <div className="flex mx-auto min-w-[315px] mb-6">
                            <span
                                className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4"
                            >1</span>
                            <span
                                className="text-lg font-semibold text-secondary-500 pt-1"
                            >Marque uma reunião para<br/>conhecer a MyPolis em detalhe;</span>
                        </div>

                        <div className="flex mx-auto min-w-[315px] mb-6">
                            <span
                                className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4"
                            >2</span>
                            <span
                                className="text-lg font-semibold text-secondary-500 pt-1"
                            >Criamos um plano<br/>personalizado para a sua<br/>autarquia;</span>
                        </div>

                        <div className="flex mx-auto min-w-[315px]">
                            <span
                                className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4"
                            >3</span>
                            <span
                                className="text-lg font-semibold text-secondary-500 pt-1"
                            >Implementamos consigo.</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start justify-center w-full mb-8 gap-x-10 forms relative">
                    <div className="flex w-full px-6 lg:px-0">
                        <div className="flex flex-col justify-end w-full gap-y-4">
                            <span className="text-[1.75rem] font-semibold font-title text-secondary-500 text-center
                            mt-4 mb-6">
                              Contacte-nos
                            </span>
                            <form id="contact-form" action=""
                                  className={`flex flex-col lg:flex-row gap-x-4 z-10 ${selectedCategory}`}>
                                <div className="flex flex-col gap-y-4 w-full lg:w-1/2">
                                    <div className="w-full">
                                        <TextInput
                                            id="full-name"
                                            placeholder="Nome"
                                            required
                                            type="text"
                                            color="input"
                                            name="fullName"
                                        />
                                    </div>
                                    <div className="w-full politician">
                                        <TextInput
                                            id="politician-role"
                                            placeholder="Cargo"
                                            required
                                            type="text"
                                            color="input"
                                            name="politician-role"
                                        />
                                    </div>
                                    <div className="w-full teacher">
                                        <TextInput
                                            name="schoolOrGrouping"
                                            id="school-or-grouping"
                                            placeholder="Escola/Agrupamento"
                                            required
                                            type="text"
                                            color="input"
                                        />
                                    </div>
                                    <div className="w-full politician">
                                        <TextInput
                                            name="cityCounsel"
                                            id="city-counsel"
                                            placeholder="Autarquia"
                                            required
                                            type="text"
                                            color="input"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInput
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            required
                                            type="email"
                                            color="input"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInput
                                            name="phone"
                                            id="phone"
                                            placeholder="Contacto Telefónico"
                                            required
                                            type="text"
                                            color="input"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-full lg:w-1/2 pt-4 lg:pt-0">
                                    <div className="flex w-full flex-grow" id="textarea">
                                        <Textarea
                                            name="message"
                                            id="message"
                                            placeholder="Mensagem"
                                            required
                                            color="input"
                                            rows={10}
                                            className="resize-none"
                                        />
                                    </div>
                                </div>
                            </form>
                            <Button pill color="primary" className="self-end w-32">
                                Enviar
                            </Button>

                            <img
                                src="/assets/images/other/spring.png"
                                alt=""
                                className="absolute -left-4 -bottom-16 lg:left-0 lg:top-[400px] lg:bottom-auto
                                max-w-[143px] z-0"
                            />

                            <img
                                src="/assets/images/other/white_star.svg"
                                alt=""
                                className="absolute right-2 -bottom-24 lg:right-[40px] lg:top-[450px] lg:bottom-auto
                                max-w-[38px] z-0"
                            />

                            <img
                                src="/assets/images/other/yellow_dialog_balloon.png"
                                alt=""
                                className="absolute right-36 -bottom-24 lg:right-[200px] lg:top-[400px] lg:bottom-auto
                                 max-w-[110px] z-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"hidden flex-col items-center w-full relative "
                + (className === "schools" ? "schools" : "")}>
                <div className="flex -mt-10 w-full justify-center">
                    <img
                        src="/assets/images/other/dialog_baloons_spread.png"
                        alt="Baloons"
                        className="hidden lg:block max-w-[1050px] mx-auto"
                    />

                    <img
                        src="/assets/images/other/baloons_mobile.png"
                        alt="Baloons"
                        className="block lg:hidden max-w-[875px] mt-8 -ml-24"
                    />
                </div>

                <div className="flex flex-col absolute top-[215px] left-1/2 -translate-x-1/2 w-full">
                    <span className="text-[1.75rem] font-semibold font-title text-secondary-500 text-center">
                      Quer uma solução chave-na-mão<br/>para Cidadania e Desenvolvimento?
                    </span>
                    <p className="text-base text-center text-secondary-500 pt-7 pb-5"
                    >Estamos cá para ajudar os/as<br/>professores/as da sua escola.</p>
                    <div className="flex flex-col">
                        <div className="flex mx-auto min-w-[315px] mb-6">
                            <span
                                className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4"
                            >1</span>
                            <span
                                className="text-lg font-semibold text-secondary-500 pt-1"
                            >Marque uma reunião para<br/>conhecer a MyPolis em detalhe;</span>
                        </div>

                        <div className="flex mx-auto min-w-[315px] mb-6">
                            <span
                                className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4"
                            >2</span>
                            <span
                                className="text-lg font-semibold text-secondary-500 pt-1"
                            >Criamos um plano<br/>personalizado<br/>para a sua turma;</span>
                        </div>

                        <div className="flex mx-auto min-w-[315px]">
                            <span
                                className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4"
                            >3</span>
                            <span
                                className="text-lg font-semibold text-secondary-500 pt-1"
                            >Oferecemos formação certificada<br/>e apoiamos a implementação.</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start justify-center w-full mb-8 gap-x-10 forms relative">
                    <div className="flex w-full px-6 lg:px-0">
                        <div className="flex flex-col justify-end w-full gap-y-4">
                            <span className="text-[1.75rem] font-semibold font-title text-secondary-500 text-center
                            mt-4 mb-6">
                              Contacte-nos
                            </span>
                            <form id="contact-form" action=""
                                  className={`flex flex-col lg:flex-row gap-x-4 z-10 ${selectedCategory}`}>
                                <div className="flex flex-col gap-y-4 w-full lg:w-1/2">
                                    <div className="w-full">
                                        <TextInput
                                            id="full-name"
                                            placeholder="Nome"
                                            required
                                            type="text"
                                            color="input"
                                            name="fullName"
                                        />
                                    </div>
                                    <div className="w-full politician">
                                        <TextInput
                                            id="politician-role"
                                            placeholder="Cargo"
                                            required
                                            type="text"
                                            color="input"
                                            name="politician-role"
                                        />
                                    </div>
                                    <div className="w-full teacher">
                                        <TextInput
                                            name="schoolOrGrouping"
                                            id="school-or-grouping"
                                            placeholder="Escola/Agrupamento"
                                            required
                                            type="text"
                                            color="input"
                                        />
                                    </div>
                                    <div className="w-full politician">
                                        <TextInput
                                            name="cityCounsel"
                                            id="city-counsel"
                                            placeholder="Autarquia"
                                            required
                                            type="text"
                                            color="input"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInput
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            required
                                            type="email"
                                            color="input"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInput
                                            name="phone"
                                            id="phone"
                                            placeholder="Contacto Telefónico"
                                            required
                                            type="text"
                                            color="input"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-full lg:w-1/2 pt-4 lg:pt-0">
                                    <div className="flex w-full flex-grow" id="textarea">
                                        <Textarea
                                            name="message"
                                            id="message"
                                            placeholder="Mensagem"
                                            required
                                            color="input"
                                            rows={10}
                                            className="resize-none"
                                        />
                                    </div>
                                </div>
                            </form>
                            <Button pill color="primary" className="self-end w-32">
                                Enviar
                            </Button>

                            <img
                                src="/assets/images/other/spring.png"
                                alt=""
                                className="absolute -left-4 -bottom-16 lg:left-0 lg:top-[350px] lg:bottom-auto
                                max-w-[143px] z-0"
                            />

                            <img
                                src="/assets/images/other/white_star.svg"
                                alt=""
                                className="absolute right-2 -bottom-24 lg:right-[40px] lg:top-[400px] lg:bottom-auto
                                max-w-[38px] z-0"
                            />

                            <img
                                src="/assets/images/other/yellow_dialog_balloon.png"
                                alt=""
                                className="absolute right-36 -bottom-24 lg:right-[200px] lg:top-[350px] lg:bottom-auto
                                 max-w-[110px] z-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"hidden flex-col items-center w-full -mb-12 "
                + (className === "team" ? "team" : "")}>
                <div className="flex -mt-10 w-full justify-center">
                    <img
                        src="/assets/images/other/dialog_baloons.png"
                        alt="Baloons"
                        className="max-w-[1050px] mx-auto hidden lg:block"
                    />

                    <img
                        src="/assets/images/other/baloons_spread_mobile.png"
                        alt="Baloons"
                        className="max-w-[550px] ml-28 block lg:hidden"
                    />
                </div>

                <div className="flex flex-col w-full">
                    <span
                        className="text-2xl font-title font-semibold text-center my-8 px-12 lg:px-0"
                    >Queres fazer parte desta equipa?</span>

                    <Button pill className="bg-white text-secondary-500 w-4/5 lg:w-2/5 mx-auto">
                        <span className="font-title font-normal">Vê as posições disponíveis aqui!</span>
                    </Button>
                </div>
            </div>

            <div className={"hidden flex-col items-center w-full -mb-12 "
                + (className === "history" ? "history" : "")}>
                <div className="flex -mt-10 w-full justify-center">
                    <img
                        src="/assets/images/other/dialog_baloons.png"
                        alt="Baloons"
                        className="max-w-[1050px] mx-auto hidden lg:block"
                    />

                    <img
                        src="/assets/images/other/baloons_spread_mobile.png"
                        alt="Baloons"
                        className="max-w-[550px] ml-28 block lg:hidden"
                    />
                </div>

                <div className="flex flex-col w-full">
                    <span
                        className="text-2xl font-title font-semibold text-center my-8 px-12 lg:px-0"
                    >Queres conhecer a nossa equipa?</span>

                    <Button pill className="bg-white text-secondary-500 w-4/5 lg:w-2/5 mx-auto">
                        <NavLink to="/sobre-nos/equipa">
                            <span className="font-title font-normal">Conhece a equipa aqui!</span>
                        </NavLink>
                    </Button>
                </div>
            </div>
        </div>
    );
};
