import {Button, Modal} from "flowbite-react";
import {Tool} from "../../../api";
import React from "react";

type ToolModalProps = {
  tool: Tool;
  openModal: boolean;
  modalCloseHandler: React.Dispatch<any>;
};

export const ToolModal =
    ({
         tool, openModal, modalCloseHandler,
     }: ToolModalProps) => {
        return (
            <Modal
                size="3xl"
                dismissible
                show={openModal}
                onClose={() => modalCloseHandler(false)}
            >
                <Modal.Body>
                    <div className="w-full h-full flex flex-col py-8 relative">
                        <Button
                            pill
                            id="flip"
                            color="white"
                            className="absolute top-0 right-0 bg-white rounded-full shadow-button w-12 h-12
                                        flex flex-col justify-center cursor-pointer border-primary-200 border-solid rotate-45"
                            onClick={() => modalCloseHandler(false)}
                        >
                            <svg
                                className="w-8 h-8 m-auto text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 18"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 1v16M1 9h16"
                                />
                            </svg>
                        </Button>
                        <span className="text-2xl font-title px-8 mb-8">{tool?.title ?? "No title provided"}</span>
                        <div className="flex flex-row overflow-x-auto">
                            <img className="min-w-[500px]" src={tool?.url} alt="Tool"/>
                        </div>
                        <span
                            className="text-3xl font-title font-semibold px-8 mt-8 mb-4"
                        >
                            {tool?.technicalSheet?.target?.map((el, i) => {
                                    if (i > 0) {
                                        return (", " + el);
                                    } else {
                                        return (el);
                                    }
                                })
                            }
                        </span>
                        <span className="text-base px-8">{tool?.description}</span>

                        <span className="text-base font-title px-8 mt-4">Objetivos:</span>
                        {tool?.technicalSheet?.objectives?.map((el, i) => {
                            return (<span className="text-base px-8 mt-4" >{el}</span>)
                        })}

                        <span className="text-base font-title px-8 mt-4">Público-alvo:</span>
                        <span className="text-base px-8">{tool?.technicalSheet?.targetAudience}</span>

                        <span className="text-base font-title px-8 mt-4">Formato:</span>
                        <span className="text-base px-8">{tool?.technicalSheet?.format}</span>

                        <span className="text-base font-title px-8 mt-4">Resultados:</span>
                        <span className="text-base px-8">{tool?.technicalSheet?.results}</span>

                        <span className="text-base font-title px-8 mt-4">Clientes:</span>
                        <div className="flex flex-wrap">
                            {tool?.technicalSheet?.customers?.map((el, i) => {
                                return (<img className="max-w-[150px] m-4" src={el} alt="Cliente"></img>)
                            })}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
