import { useEffect, useState } from "react";
import { ImageGridDisplay } from "../../molecules/image-grid-display/image-grid-display";
import { MockServices } from "../../../mock/MockServices";

type HowToParticipateProps = {
  className?: string;
};

export const HeroHowToParticipate = ({
  className = "",
}: HowToParticipateProps) => {
  const [customers, setCustomers] = useState([] as Array<any>);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  const fetchCustomers = () => {
    setDataIsLoading(true);
    MockServices.CustomersMockService.getCustomers().then((customers) => {
      setCustomers(
        customers.map((customer) => ({
          src: customer.logo,
          alt: customer.name,
        }))
      );
      setDataIsLoaded(true);
    });
  };

  useEffect(() => {
    if (!dataIsLoading || !dataIsLoaded) fetchCustomers();
  }, [dataIsLoaded, dataIsLoading]);

  return (
    <div
      className={`${className} hero-how-to-participate font-title w-full text-center`}
    >
      <div className="w-54 h-[110px] mt-12 mb-13 bg-white rounded-card  mx-auto relative py-4 px-8">
        <span className="w-full font-semibold leading-9 text-xl">
          Como participar
        </span>
        <img
          src="/assets/images/lines/curve.svg"
          alt="Curve"
          className="absolute -scale-100 rotate-[25deg] bottom-2 right-5"
        />
        <img
          src="/assets/images/lines/curve.svg"
          alt="Curve"
          className="absolute top-[1px] left-12 rotate-[45deg]"
        />
        <img
          className="absolute scale-75 -bottom-12 -right-[4.4rem]"
          src="/assets/images/other/point_up_baloons.png"
          alt="Plant"
        />
        <img
          className="absolute scale-[70%] -bottom-[4.2rem] -left-[6.3rem]"
          src="/assets/images/other/high_five_star.png"
          alt="Plant"
        />
      </div>
      <div className="mx-auto w-fit text-base text-primary-900/50 mb-8">
        Fazes parte de uma <br /> destas comunidades?
      </div>
      <ImageGridDisplay items={customers.concat(customers)} />
    </div>
  );
};
