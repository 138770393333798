import { CustomFlowbiteTheme } from "flowbite-react";

const theme: CustomFlowbiteTheme = {
  button: {
    base: "focus:outline-none shadow-button group flex items-stretch items-center justify-center p-0.5 text-center font-medium relative focus:z-10 focus:outline-none",
    color: {
      primary: "bg-primary-500 hover:bg-primary-600 text-white",
      secondary: "bg-secondary-500 text-white hover:bg-secondary-600",
      white: "bg-white text-secondary-500 hover:bg-gray-100",
      link: "bg-white text-black hover:text-underline",
      bg: "bg-bg-500 hover:bg-bg-600 text-secondary-500",
    },
    size: {
      xs: "text-xs px-2 py-1",
      sm: "text-sm px-3 py-1.5",
      md: "text-sm px-4 py-2",
      lg: "text-base px-5 py-2.5",
      xl: "text-base px-6 py-3",
      footer: "text-2xl px-4 py-2 font-normal",
    },
  },
  navbar: {
    root: {
      inner: {
        base: "mx-auto flex items-center h-full",
      },
    },
    // link: {
    //   base: "relative w-30 hover:bg-gray-100 bg-transparent text-secondary-950 hover:text-secondary-950 hover:underline active:text-bold h-full",
    //   active: {
    //     on: "",
    //     off:""
    //   }
    // },
    collapse: {
      base: "",
      list: "mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium",
    },
  },
  textInput: {
    field: {
      input: {
        base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 shadow-input bg-bg-500 border-0",
        colors: {
          input:
            "bg-[#FDEFE1] placeholder-secondary-500 text-secondary-500 text-md",
        },
      },
    },
  },
  textarea: {
    base: "block w-full rounded-lg border disabled:cursor-not-allowed disabled:opacity-50 text-sm border-0 shadow-input placeholder:text-sm",
    colors: {
      input:
        "bg-[#FDEFE1] placeholder-secondary-500 text-secondary-500 text-md",
    },
  },
  footer: {
    brand: {
      base: "mb-4 flex items-center sm:mb-0 text-bg-500",
      img: "w-60 h-auto",
    },
    title: {
      base: "mb-5 text-sm font-semibold uppercase text-bg-500 font-body text-lg",
    },

    groupLink: {
      base: "flex flex-wrap text-sm text-bg-500 font-body text-md",
      link: {
        base: "focus:outline-none text-lg",
        href: "focus:outline-none hover:underline",
      },
    },
    icon: {
      base: "",
      size: "h-11 w-11 lg:h-12 lg:w-12",
    },
    copyright: {
      base: "text-sm text-bg-500",
    },
  },
  card: {
    root: {
      base: "flex rounded-card bg-white shadow-card m-2",
      children: "flex h-full flex-col justify-center gap-2 w-full h-full",
    },
    img: {
      base: "rounded-3xl",
      horizontal: {
        off: "",
      },
    },
  },
  modal: {
    root: {
      sizes: {
        sm: "max-w-sm",
        md: "max-w-md",
        lg: "max-w-lg",
        xl: "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
        "5xl": "max-w-5xl",
        "6xl": "max-w-6xl",
        "7xl": "max-w-7xl",
        screen: "max-w-screen max-h-screen h-screen w-screen",
      },
    },
    content: {
      inner:
        "relative rounded-card bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh]",
    },
  },
  dropdown: {
    content: "focus:outline-none",
    floating: {
      base: "z-10 w-auto rounded divide-y divide-gray-100 shadow focus:outline-none",
    },
  },
};

export default theme;
