import { Customer } from "../../api";

export const Customers: Customer[] = [
  {
    id: 1,
    name: "Município de Portimão",
    shortName: "Portimão",
    slug: "portimao",
    logo: "https://www.cm-portimao.pt/images/elementosGraficosGerais/logoCMP2.png",
    location: {
      latitude: 37.1801339,
      longitude: -8.6176795,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
  {
    id: 2,
    name: "Município de Torres Novas",
    shortName: "Torres Novas",
    slug: "torres-novas",
    logo: "https://eportugal.gov.pt/documents/20142/407917/cm_torres_novas.png04d7d981-92b2-4174-b54a-05c4efebe8cdFromCES/d38df0fb-aad1-c772-9883-fa57e320d4aa?t=1648049171068",
    location: {
      latitude: 39.4833448,
      longitude: -8.5864,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
  {
    id: 3,
    name: "Município de Lagoa",
    shortName: "Lagoa",
    slug: "lagoa",
    logo: "https://www.aprh.pt/wp-content/uploads/2023/01/logo_cm-lagoa.png",
    location: {
      latitude: 37.1181957,
      longitude: -8.4787355,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
  {
    id: 4,
    name: "Município de Torres Vedras",
    shortName: "Torres Vedras",
    slug: "torres-vedras",
    logo: "https://plataforma285.com/wp-content/uploads/2019/07/CMTV_horizontal.png",
    location: {
      latitude: 39.1101074,
      longitude: -9.3563142,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
  {
    id: 5,
    name: "Município de Oeiras",
    shortName: "Oeiras",
    slug: "oeiras",
    logo: "https://cdn.discordapp.com/attachments/1081187903448825946/1168608951663853680/image_11.png?ex=655262e5&is=653fede5&hm=e097eec59309b7e5aa7d24cdf5a969ae6f52ecf648806d779cd6bcdecc718326&",
    location: {
      latitude: 38.7125358,
      longitude: -9.3116433,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
  {
    id: 6,
    name: "Freguesia de Massamá e Monte Abraão",
    shortName: "Massamá e Monte Abraão",
    slug: "mma",
    logo: "https://www.dataroad-it.com/wp-content/uploads/2019/12/junta_massama.png",
    location: {
      latitude: 38.7575983,
      longitude: -9.316781,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
  {
    id: 7,
    name: "União das Freguesias de Coimbra",
    shortName: "UF Coimbra",
    slug: "ufcoimbra",
    logo: "https://www.ufcoimbra.pt/frontend/images/logo.png",
    location: {
      latitude: 40.2253517,
      longitude: -8.617096,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
  {
    id: 8,
    name: "Município de Vila Nova de Poiares",
    shortName: "Vila Nova de Poiares",
    slug: "vnpoiares",
    logo: "https://cm-vilanovadepoiares.pt/images/municipio/Bras%C3%A3o-Vila-Nova-de-Poiares.png",
    location: {
      latitude: 40.2149489,
      longitude: -8.3575247,
    },
    metrics: [
      {
        name: "youthInvolved",
        data: "12000",
        description: "Jovens envolvidos",
      },
      { name: "schools", data: "17", description: "Escolas participantes" },
    ],
    products: [
      "Mansão Civitas",
      "Exploradores da Cidadania",
      "Conselheiros da Cidadania",
      "App MyPolis",
    ],
  },
];
