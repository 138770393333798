import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";

import "./styles/index.scss";

import App from "./App";
import { ErrorBoundary } from "react-error-boundary";
import Router from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary
    FallbackComponent={({ error }) => {
      console.log(error);

      return <></>;
    }}
  >
    <React.StrictMode>
      {/* <BrowserRouter> */}
      {/* <RouterProvider router={Router()} /> */}
      <App />
      {/* </BrowserRouter> */}
    </React.StrictMode>
  </ErrorBoundary>
);
