"use client";

import { Button, Navbar } from "flowbite-react";
// import "./navbar.css";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavbarDropdownLink } from "../../atoms/navbar-link-group/navbar-dropdown-link";
import { NavbarLink } from "../../atoms/navbar-link/navbar-link";
import "./navbar.scss";

export const CustomNavbar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();

  const handleClick = () => {
    setModalOpen((current) => !current);
  };

  useEffect(() => {
    if (location.pathname.includes("sobre-nos")) {
      document.getElementById("dropdown-hover")?.classList.add("active");
    } else {
      document.getElementById("dropdown-hover")?.classList.remove("active");
    }
  }, [location]);

  return (
    <>
      <Navbar
        id="nav"
        className="py-0 shadow-md h-navbar justify-end relative px-0 sm:px-0 lg:px-2"
        fluid
        rounded
        style={{ padding: modalOpen ? "0 0" : "" }}
      >
        <NavLink className="flex items-center" to="/">
          <img
            src="/assets/images/logos/logo_mypolis.png"
            className="h-8 mx-3"
            alt="MyPolis Logo"
          />
        </NavLink>
        <div className="flex items-center h-full mr-3 relative -right-12 lg:right-0 lg:static lg:ml-auto">
          <ul
            style={{
              left: modalOpen ? 0 : "",
            }}
            className={`overflow-hidden mt-15 top-0 left-full fixed w-screen bg-white z-40 flex flex-col justify-start items-center h-full navbar-link-container lg:static lg:flex-row lg:overflow-auto lg:w-auto lg:items-center lg:justify-normal lg:mt-0 lg:max-h-full`}
          >
            <NavbarLink linkClasses="mt-2 lg:hidden" to="/">
              <img
                className="w-6 h-6 mr-1 mb-1"
                src="/assets/images/other/smol-home.png"
                alt="Small House"
              />
              Página Inicial
            </NavbarLink>
            <NavbarLink to="/autarquias">Autarquias</NavbarLink>
            <NavbarLink to="/escolas">Escolas</NavbarLink>
            <NavbarLink linkClasses="lg:hidden" to="/como-participar">
              Como Participar
            </NavbarLink>
            <NavbarDropdownLink
              containerClasses="min-w-[150px]"
              dropDownText="Sobre Nós"
            >
              <NavbarLink
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to="/sobre-nos/historia"
              >
                História
              </NavbarLink>
              <NavbarLink
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to="/sobre-nos/equipa"
              >
                Equipa
              </NavbarLink>
              <NavbarLink
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to="/sobre-nos/ferramentas"
              >
                Ferramentas
              </NavbarLink>
              <NavbarLink
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to="/sobre-nos/impacto"
              >
                Impacto
              </NavbarLink>
            </NavbarDropdownLink>
            <NavbarLink linkClasses="hidden lg:block" to="/como-participar">
              Como Participar?
            </NavbarLink>
            <NavbarLink
              to="/login"
              disabled={true}
              linkClasses="hidden  lg:block"
            >
              Entrar
            </NavbarLink>
          </ul>
          <Button pill color="primary" className="m-auto ml-5 font-bold">
            Criar Conta
          </Button>

          <div
            className={`hamburger-menu gap-y-[6px] ${
              modalOpen ? "open" : ""
            } flex flex-col overflow-hidden justify-center items-center rounded-full w-10 h-10 ml-4 shadow-hamburger cursor-pointer border-primary-200 border-solid z-[60] lg:hidden`}
            onClick={handleClick}
          >
            <div
              className={`w-1/2 border-t-1 border-secondary-950 ${
                modalOpen ? "-rotate-45 translate-y-[7px]" : ""
              }`}
            ></div>
            <div
              className={`w-1/2 border-t-1 border-secondary-950 ${
                modalOpen ? "opacity-0" : "opacity-1"
              }`}
            ></div>
            <div
              className={`w-1/2 border-t-1 border-secondary-950 ${
                modalOpen ? "rotate-45 -translate-y-[7px]" : ""
              }`}
            ></div>
          </div>

          {/* <Button
            pill
            id="flip"
            color="white"
            className="bg-white rounded-full shadow-button w-10 h-10 ml-4
                        flex justify-center cursor-pointer border-primary-200 border-solid lg:hidden"
            onClick={handleClick}
            theme={{ size: {
              md: "p-0"
            } }}
          >
            <svg
              className="w-6 h-6 m-auto text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="secondary"
              viewBox="0 0 100 65"
            >
              <rect width="100" height="5"></rect>
              <rect y="30" width="100" height="5"></rect>
              <rect y="60" width="100" height="5"></rect>
            </svg> 
          </Button>*/}
        </div>
      </Navbar>
    </>
  );
};
