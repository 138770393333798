import {ContactUs} from "../../organisms/contact-us/contact-us";
import {HeroMunicipalities} from "../../organisms/hero-municipalities/hero-municipalities";
import {Metrics} from "../../organisms/metrics/metrics";
import {Impact} from "../../organisms/impact/impact";
import {Feedback} from "../../organisms/feedback/feedback";
import {Tools} from "../../organisms/tools/tools";
import {Backoffice} from "../../organisms/backoffice/backoffice";
import {CustomerMap} from "../../organisms/customer-map/customer-map";


const Municipalities = () => {
    return (
        <div id="page" className="municipalities">
            <HeroMunicipalities/>

            <Metrics/>

            <Impact/>
            <br/>
            <Tools/>
            <Backoffice/>
            <br />
            <CustomerMap />
            <br/>
            <Feedback/>
            <ContactUs className={"municipalities"}/>
        </div>
    );
};

export default Municipalities;
