import { Button, Footer, TextInput } from "flowbite-react";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";

export const CustomFooter = () => {
  return (
    <Footer
      id="footer"
      container
      className="py-4 px-8  mt-24 rounded-t-4xl bg-primary-500 font-body lg:px-16 lg:py-7"
    >
      <div className="lg:w-3/6 m-auto">
        <Button
          pill
          size="footer"
          color="secondary"
          className="py-2 m-auto text-2xl font-title w-80 lg:w-auto lg:px-20"
        >
          Marcar Reunião
        </Button>
        <div className="flex flex-col w-full mt-12 justify-evenly gap-x-14 lg:items-start">
          <div
            id="footer-top-half"
            className="flex flex-col w-full lg:flex-row"
          >
            <div className="flex flex-col w-full gap-y-5 text-bg-500 lg:w-1/2">
              <Link to="/">
                <Footer.Brand
                  className="max-w-max m-auto lg:m-0"
                  alt="MyPolis Logo"
                  src="/assets/images/logos/logo_mypolis_white_slogan.png"
                />
              </Link>
              <span>
                "A MyPolis abre a porta para os cidadãos e cidadãs que querem
                melhorar as suas cidades, ligando-os a políticos e promovendo a
                colaboração na comunidade. A MyPolis está a construir uma nova
                Polis digital, onde os millennials participam através dos seus
                telemóveis de uma forma divertida, simples e intuitiva."
              </span>
            </div>
            <div className="flex flex-row w-full mt-10 lg:mt-0 lg:pl-[40px] lg:w-1/2">
              <div className="flex flex-col gap-y-5 items-start m-auto justify-evenly w-full gap-x-8 lg:flex-row lg:gap-y-0">
                <div>
                  <Footer.LinkGroup col className="font-semibold">
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/"
                    >
                      Página Inicial
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/autarquias"
                    >
                      Autarquias
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/escolas"
                    >
                      Escolas
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/como-participar"
                    >
                      Como Participar?
                    </Link>
                  </Footer.LinkGroup>
                </div>
                <div className="flex flex-col gap-y-2">
                  <Footer.Title title="Sobre Nós" className="capitalize mb-0" />
                  <Footer.LinkGroup
                    col
                    className="flex flex-col indent-6 space-y-2 gap-y-0.5"
                  >
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/sobre-nos/historia"
                    >
                      História
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/sobre-nos/equipa"
                    >
                      Equipa
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/sobre-nos/ferramentas"
                    >
                      Ferramentas
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/sobre-nos/impacto"
                    >
                      Impacto
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline"
                      to="/recrutamento"
                    >
                      Recrutamento
                    </Link>
                    <Link
                      className="focus:outline-none text-lg hover:underline hidden"
                      to="blog"
                    >
                      Blog
                    </Link>
                  </Footer.LinkGroup>
                </div>
              </div>
            </div>
          </div>
          <div
            id="footer-bottom-half"
            className="flex flex-col w-full mt-12 items-center justify-center lg:flex-row lg:mt-0"
          >
            <div className="flex flex-col-reverse w-full text-bg-500 lg:flex-col">
              <div className="flex w-full mb-20 flex-row mt-6 lang lg:w-1/2 lg:mt-11 lg:mb-12">
                <Button pill color="white" className="h-12">
                  <img
                    src="/assets/images/lang/pt.svg"
                    alt=""
                    className="w-8 h-auto mr-4 "
                  />
                  Português
                </Button>
              </div>
              <div className="">
                CASA DO IMPACTO
                <br />
                Tv. de São Pedro 8, 1200-432 Lisboa
                <br />
                <a href="mailto:hello@mypolis.eu">hello@mypolis.eu</a>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <span className="text-lg font-title text-bg-500">
                Assine a nossa Newsletter
              </span>
              <form id="politician" action="" className="mt-2">
                <div className="w-full">
                  <TextInput
                    id="email"
                    placeholder="Coloque aqui o seu e-mail"
                    required
                    type="text"
                    color="input"
                  />
                </div>
              </form>
              <Button
                pill
                color="bg"
                className="self-end w-20 mt-3 font-semibold"
              >
                Enviar
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full mt-7 lg:mt-6">
          <span className="font-title text-[18px] hidden lg:block text-bg-500">
            Siga-nos
          </span>
          <div
            id="socials"
            className="flex flex-grow max-w-max w-auto m-auto h-11 items-start gap-x-4 lg:h-12 text-white lg:m-0 lg:mt-2"
          >
            <Footer.Icon href="#" className="h-" icon={BsFacebook} />
            <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="#" icon={BsLinkedin} />
          </div>
        </div>
        <div className="flex flex-col items-center w-full mt-16">
          <div className="flex flex-row items-center mb-1 text-sm w-full justify-center text-bg-500">
            <Footer.Link href="#" className="text-sm list-none mr-5">
              Privacidade
            </Footer.Link>
            <Footer.Link href="#" className="text-sm mr-5">
              Termos e condições
            </Footer.Link>
            <Footer.Link href="#" className="text-sm">
              Manifesto
            </Footer.Link>
          </div>
          <Footer.Copyright by="MyPolis" href="#" year={2023} />
        </div>
      </div>
    </Footer>
  );
};
