import {Button} from "flowbite-react";

export const ScheduleMeeting = () => {
    return (
        <div className="flex w-full relative my-32">
            <Button pill className="mx-auto px-8 h-14" color="secondary">
                <span className="text-2xl text-white font-title font-normal">Marcar Reunião</span>
            </Button>

            <img
                src="/assets/images/other/schedule_meeting.png"
                alt="Schedule Meeting"
                className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] max-w-[545px]"
                />
        </div>
    );
}