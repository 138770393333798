import {useEffect, useState} from "react";
import {MockServices} from "../../../mock/MockServices";
import {Customer} from "../../../api";
import {CarouselItem} from "../../atoms/carousel/carousel-item";
import "./partner-carousel.scss";

export const PartnerCarousel = () => {
    const [customers, setCustomers] = useState(new Array<Customer>());

    const fetchData = async () => {
        return MockServices.CustomersMockService.getCustomers().then((customers) =>
            setCustomers(customers)
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    const logos = customers.map((customer, i) => {
        return (
            <CarouselItem
                key={i}
                imgUrl={customer.logo}
                imgTitle={customer.name}
                width={`auto`}
            ></CarouselItem>
        );
    });

    return (
        <div
            className="w-full lg:w-[calc(100vw+30px)] lg:-left-2 absolute h-24
                       overflow-x-auto bg-white carousel-container shadow-carousel lg:rotate-5">
            <div className="flex items-center justify-start carousel-track py-2 w-fit h-full bg-white">
                {logos}
                {logos}
                {logos}
                {logos}
            </div>
        </div>
    );
};
