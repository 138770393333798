import {TextFigure} from "../../molecules/text-figure/text-figure";
import {Figure} from "../../atoms/figure/figure";

type historyListType = {
    title: string;
    text: string;
    image: string;
    description: string;
}

const historyList: Array<historyListType> = [
    {
        title: "",
        text: "A MyPolis nasceu da vontade de criar formas de " +
            "<span class='bg-yellow-300 p-0.5'>participação democrática</span> alternativas para os jovens, " +
            "alinhadas com as suas necessidades e com causas mobilizadoras como o ambiente, a igualdade ou os " +
            "direitos humanos.<br/><br/>Demos os primeiros passos no final de 2017, começando por criar uma " +
            "plataforma digital de democracia participativa e firmando parcerias com 10 autarquias para lançar a " +
            "plataforma nesses territórios, promovendo a participação dos jovens e " +
            "<span class='bg-yellow-300 p-0.5'>fortalecendo a democracia</span> a nível local.",
        image: "/assets/images/history/MyPolis_team_2018.png",
        description: "Equipa MyPolis 2018"
    },
    {
        title: "",
        text: "Em 2018, entrámos na Casa do Impacto da SCML, onde co-habitamos com vários projectos de inovação " +
            "social. Nos últimos anos, procurámos criar ferramentas que tornem a participação cívica mais " +
            "<span class='bg-yellow-300 p-0.5'>simples, inclusiva e divertida</span> e que abram a porta ao diálogo com representantes políticos para " +
            "todos/as. Mais de 25.000 jovens já participaram através da MyPolis em " +
            "<span class='bg-yellow-300 p-0.5'>23 autarquias.</span><br/><br/><br/><br/>" +
            "Nas escolas, transformamos as salas de aula em academias de participação, promovendo uma Educação " +
            "para a Cidadania mais participativa e focada na intervenção directa na comunidade. Esta intervenção é " +
            "considerada muito positiva por " +
            "<span class='bg-yellow-300 p-0.5'>99% dos Professores/as que trabalharam connosco.</span><br/>" +
            "Na sequência deste trabalho, criámos novas respostas educativas e jogos que permitem às crianças e " +
            "jovens aprender a ser cidadãos e cidadãs participativos/as de forma divertida, com impacto efectivo nas " +
            "suas comunidades e colaboração entre todos/as.",
        image: "/assets/images/history/history_2.png",
        description: "Assembleia Jovem MyPolis em Lagoa<br/>18 de Maio de 2022"
    },
];

const historyElement = {
    title: "",
    text: "A MyPolis tem promovido uma linha directa de " +
        "<span class='bg-yellow-300 p-0.5'>comunicação digital entre jovens e representantes,</span> " +
        "criando impacto em diferentes territórios. Plantação de árvores, atendimento inclusivo para invisuais, " +
        "uma papelaria solidária, hortas nas escolas, mais paragens de autocarro, uma feira solidária que " +
        "recolheu meia tonelada de alimentos e combate ao bullying nas escolas.<br/><br/> " +
        "Há muitas propostas a ser implementadas em cada vez mais lugares do país. Há projetos simples, " +
        "planeados e executados por uma turma ou associação e projetos partilhados com as autarquias. A MyPolis " +
        "está a reacender o interesse dos jovens participantes em " +
        "<span class='bg-yellow-300 p-0.5'>resolver os problemas das suas comunidades.</span>",
    image: "/assets/images/history/history_3.png",
    description: "Aluno em ação na Escola Secundária<br/>Luís Freitas Branco, Oeiras"
}

export const HistoryList = () => {
    return (
        <div className="flex flex-col mt-24 lg:mt-36 z-10">
            <div className="flex mb-8 w-full lg:mb-16">
                <div className="mx-auto bg-white px-12 lg:px-20 py-7 rounded-2xl relative">
                    <img
                        src="/assets/images/other/white_star.svg"
                        alt="Baloon"
                        className="absolute  max-w-[40px] brightness-0
                        lg:-bottom-8 lg:-left-8 lg:max-w-[40px]"
                    />
                    <img
                        src="/assets/images/other/white_star.svg"
                        alt="Baloon"
                        className="absolute  max-w-[40px] brightness-0
                        lg:-top-12 lg:right-1 lg:max-w-[32px]"
                    />
                    <img
                        src="/assets/images/other/dialog_baloon_red_2.png"
                        alt="Curve"
                        className="absolute -top-18 left-4 max-w-[105px]"
                    />
                    <img
                        src="/assets/images/other/pencil.png"
                        alt="Baloon"
                        className="absolute -bottom-16 -right-14 max-w-[205px]  lg:max-w-[205px]"
                    />
                    <span className="text-2xl sm:text-2.5xl font-semibold font-title text-secondary-500"
                    >A Nossa<br className="block lg:hidden"/> História</span>
                </div>
            </div>

            <div className="flex flex-row flex-wrap justify-around mx-auto z-10 relative">
                {historyList.map((el, i) => {
                    return (
                        <TextFigure
                            title={el.title}
                            text={el.text}
                            maxLength="max-w-[85%]"
                            srcImage={el.image}
                            description={el.description}
                            reverse={i % 2 !== 0}
                            key={i}
                        />
                    );
                })}
            </div>

            <div className="flex w-full justify-center mt-10 mb-20 relative">
                <Figure
                    srcImage="/assets/images/mocks/mock_mc.png"
                    description="Ecrã Inicial do Jogo 'Mansão Civitas'"
                    maxWidth="max-w-[500px]"
                />

                <img
                    src="/assets/images/lines/spiral_down.png"
                    alt="Curve"
                    className="absolute -bottom-12 left-52 max-w-[175px] -scale-y-100 rotate-[35deg]"
                />
            </div>

            <div className="flex flex-row flex-wrap justify-around mx-auto z-10 relative">
                <TextFigure
                    title={historyElement.title}
                    text={historyElement.text}
                    maxLength="max-w-[85%]"
                    srcImage={historyElement.image}
                    description={historyElement.description}
                />
            </div>

            <div className="flex w-full justify-center mt-10 mb-20 relative">
                <Figure
                    srcImage="/assets/images/history/MyPolis_team_2021.png"
                    description="Equipa MyPolis 2021"
                    maxWidth="max-w-[785px]"
                />

                <img
                    src="/assets/images/other/rounded_red_baloon.png"
                    alt="Curve"
                    className="absolute -top-12 left-40 max-w-[105px]"
                />

                <img
                    src="/assets/images/other/spring.png"
                    alt="Curve"
                    className="absolute top-14 left-6 max-w-[105px]"
                />

                <img
                    src="/assets/images/other/baloon_yellow.png"
                    alt="Curve"
                    className="absolute -top-12 right-40 max-w-[105px]"
                />

                <img
                    src="/assets/images/other/dialog_baloon_clear_sharp.png"
                    alt="Curve"
                    className="absolute top-10 right-14 max-w-[105px]"
                />

                <img
                    src="/assets/images/other/black_baloon.png"
                    alt="Curve"
                    className="absolute bottom-20 right-14 max-w-[105px]"
                />
            </div>

            <div className="flex flex-col w-full">
                <span className="font-title font-medium text-xl text-center w-fit mx-auto relative">
                    "Adorei trabalhar com<br/>a Mypolis, as aulas ajudaram-nos a ser<br/>autónomos, a saber
                    estruturar as nossas<br/>respostas e como ajudar a nossa Camara<br/>Municipal, obrigado pela
                    experiência!"

                    <img src="/assets/images/lines/curve.png" alt="Curve"
                         className="absolute -top-6 -left-6 max-w-[50px]"
                    />

                    <img src="/assets/images/lines/curve.png" alt="Curve"
                         className="absolute -bottom-6 -right-12 max-w-[50px] rotate-180"
                    />
                </span>
                <span className="font-medium italic text-xl text-center w-fit mx-auto mt-6">(Aluno/a)</span>
            </div>
        </div>
    )
}