import { HeroHowToParticipate } from "../../organisms/hero-how-to-participate/hero-how-to-participate";

const HowToParticipate = () => {
  return (
    <div id="page" className="how-to-participate">
      <HeroHowToParticipate />
    </div>
  );
};

export default HowToParticipate;
