import {Button} from "flowbite-react";
import "./hero-municipalities.scss";

export const HeroMunicipalities = ({className}: any) => {
    return (
        <div className={className + " hero-municipalities gap-x-2 flex justify-around w-full pt-10 -mb-24"}>
            <div className="flex flex-col items-center justify-around w-full lg:w-1/2 gap-y-10 lg:px-4">
                <div className="flex flex-col items-start justify-center text-4xl lg:text-5xl
                                text-secondary-500 font-title gap-y-2 lg:-rotate-5 w-full">
                    <div className="flex flex-col mx-auto w-fit">
                        <div className="flex mt-8 px-4 lg:py-2 lg:bg-yellow-300">
                            <span className="">
                                A <span className="px-2 py-2 lg:px-0 lg:py-0 bg-yellow-300"> participação</span>
                            </span>
                        </div>

                        <div className="flex items-center mt-2 px-3 z-0">
                            <span className="flex flex-row">
                                dos&nbsp;
                                <span className="flex flex-col gap-y-2">
                                      Jovens{" "}
                                    <img
                                        className="-mt-6 rotate-6"
                                        src="/assets/images/lines/downward_curve.svg"
                                        alt="Downward curving line"
                                    />
                                </span>
                            </span>
                        </div>
                        <div className="flex flex-row -mt-6 px-4 z-10">
                            <span className="py-2">começa&nbsp;</span>
                            <span className="px-4 py-2 rounded-full w-max text-white bg-primary-500
                                             lg:text-secondary-500 lg:bg-yellow-300">
                                aqui!
                            </span>
                        </div>
                    </div>

                    <img
                        className="block lg:hidden w-[375px] mx-auto rotate-5"
                        src="/assets/images/other/handshake_mobile.png"
                        alt="Handshake"
                    />

                    <div className="text-xl text-primary-900/50 -mt-10 lg:mt-2 px-4">
                        Atingimos resultados em
                        <br/>
                        23 autarquias portuguesas.
                    </div>
                </div>
                <div className="flex flex-col w-5/6 relative mt-4 mr-auto lg:mr-0">
                    <div className="w-full px-4">
                        <Button pill className="w-full h-12" color="secondary">
                            <span className="text-xl font-semibold">Marcar Reunião</span>
                        </Button>
                    </div>

                    <img
                        className="rotate-180 w-1/4 absolute left-0 lg:left-32 max-w-[120px]"
                        id="arrow-down-twirl"
                        src="/assets/images/arrows/arrow_down_twirl.png"
                        alt="Arrow down"
                    />
                </div>
            </div>

            <div className="hidden lg:flex w-1/2">
                <img
                    className="w-4/5 mx-auto"
                    src="/assets/images/other/handshake.png"
                    alt="Handshake"
                />
            </div>
        </div>
    );
};
