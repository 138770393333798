/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Post = {
    id?: number;
    title?: string;
    postType?: Post.postType;
    tag?: Array<{
        name?: string;
        order?: number;
    }>;
    body?: string;
    location?: {
        latitude?: number;
        longitude?: number;
    };
    implementationDate?: string;
    community?: string;
    media?: Array<string>;
    banner?: string;
    publicationDate?: string;
    techSheet?: Record<string, any>;
};

export namespace Post {

    export enum postType {
        NOTICIA = 'Noticia',
        PROPOSTA = 'Proposta',
    }


}

