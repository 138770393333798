import { Outlet } from "react-router-dom";
import { Navbar, Footer } from "../organisms";
import { ScrollToTop } from "../../utilities/scroll-to-top";

export const Layout = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};
