import "./hero-impact.scss";

export const HeroImpact = ({ className }: any) => {
  return (
    <div
      className={
        className +
        " hero-impact gap-x-2 flex flex-col justify-around w-full pt-10 lg:flex-row -mb-10 lg:mb-0"
      }
    >
      <div className="flex flex-col items-center justify-around w-full gap-y-10 relative lg:w-1/2">
        <div
          className="flex flex-col items-start justify-center text-3xl
        text-secondary-500 font-title gap-y-2 w-full px-4 lg:-rotate-5 lg:text-5xl"
        >
          <div className="flex flex-col w-fit mx-auto">
            <span className="px-4 w-fit lg:mt-8">Estamos</span>
            <span className="px-4 py-2 w-fit">a reforçar a</span>
            <span className="ml-2 px-2 py-2 bg-secondary-500 text-white w-fit lg:px-4 lg:ml-0">
              qualidade
            </span>
            <span className="px-4 py-2 w-fit">da nossa</span>
            <span className="px-4 py-2 rounded-full bg-danger-500 lg:bg-secondary-500 text-white w-fit">
              democracia!
            </span>

            <div className="text-base text-primary-900/50 mt-6 px-4 lg:mt-2">
              As nossas ferramentas <br className="lg:hidden" /> permitem&nbsp;
              <br className="hidden lg:block" />
              processos <br className="lg:hidden" /> participativos
              inovadores,&nbsp;
              <br className="hidden lg:block" />
              que <br className="lg:hidden" /> reforçam os laços entre jovens,
              <br />
              representantes e instituições&nbsp;
              <br className="lg:hidden" />
              democráticas
              <br className="hidden lg:block" /> e, permitem um&nbsp;
              <br className="lg:hidden" /> maior conhecimento mútuo.
            </div>
          </div>
        </div>

        <img
          className="max-w-[50px] absolute top-24 right-0 brightness-0 hidden lg:block"
          src="/assets/images/other/white_star.svg"
          alt="Star"
        />

        <img
          className="max-w-[30px] absolute top-48 -right-5 brightness-0 hidden lg:block"
          src="/assets/images/other/white_star.svg"
          alt="Star"
        />

        <img
          className="max-w-[68px] rotate-[13deg] absolute top-[120px] right-20 lg:hidden"
          src="/assets/images/other/yellow_baloon_straight.png"
          alt="Star"
        />

        <img
          className="max-w-[70px] absolute top-60 right-16 lg:hidden"
          src="/assets/images/other/purple_dialog_baloon.png"
          alt="Star"
        />
      </div>

      <div className="w-full pt-8 lg:w-1/2">
        <img
          className="w-4/5 mx-auto hidden lg:block"
          src="/assets/images/other/growing.png"
          alt="Plant"
        />
        <img
          className="w-4/5 max-w-[315px] mx-auto lg:hidden"
          src="/assets/images/other/growing_mobile.png"
          alt="Plant"
        />
      </div>
    </div>
  );
};
