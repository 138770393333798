import { Button } from "flowbite-react";
import "./hero.scss";

export const Hero = ({ className }: any) => {
  return (
    <div className={className + " hero gap-x-14 flex flex-col-reverse sm:flex-row justify-around w-full pt-10 mb-10 relative"}>
      <div className="w-full lg:w-1/2 -mt-20 lg:mt-0">
        <img
          className="hidden lg:block w-4/5 mx-auto"
          src="/assets/images/other/loud_speaker.png"
          alt="Loud Speaker"
        />

        <img
            className="block lg:hidden w-[235px] ml-auto mr-10"
            src="/assets/images/other/loud_speaker_mobile.png"
            alt="Loud Speaker"
        />
      </div>
      <div className="flex flex-col items-center justify-around w-full lg:w-1/2 gap-y-10 px-4">
        <div className="flex flex-col items-start justify-center w-full text-5xl text-secondary-500 font-title gap-y-2 -rotate-5">
          <span className="px-4">
            A <span className="px-3 text-white bg-primary-500">criar</span>
          </span>
          <div className="flex items-center mt-2 px-4">
            Es
            <div className="image-grow flex">
              <img
                className="rotate-6 m-auto"
                src="/assets/images/arrows/double_arrow.svg"
                alt="Double sided arrow"
              />
            </div>
            paço
          </div>
          <span className="px-4">para uma</span>
          <span className="mt-2 px-4 py-2 text-white rounded-full w-max bg-danger-500">
            Democracia
          </span>
          <span className="flex flex-row mt-2 px-4">
            para&nbsp;
            <span className="flex flex-col gap-y-2">
              todos!&nbsp;
              <div className="-mt-2 draw">
                <img
                  className="rotate-6"
                  src="/assets/images/lines/downward_curve.png"
                  alt="Downward curving line"
                />
              </div>
            </span>
          </span>
          <div className="text-xl text-primary-900/50 px-4">
            Vem transformar a tua
            <br />
            comunidade, em parceria
            <br />
            com a tua autarquia!
          </div>
        </div>
        <div className="flex flex-col w-full gap-y-6 mt-5">
          <div className="flex flex-row justify-between w-full relative px-4">
            <Button pill color="primary" className="hidden lg:flex w-2/5 h-12">
              <span className="text-xl font-semibold">Criar Conta</span>
            </Button>

            <img
              className="hidden lg:block absolute right-0 max-w-[120px]"
              id="arrow-down-twirl"
              src="/assets/images/arrows/arrow_down_twirl.png"
              alt="Arrow down"
            />

            <Button
              pill
              color="white"
              className="hidden lg:flex w-2/5 h-12 text-secondary-500"
            >
              <span className="text-xl font-semibold">Entrar</span>
            </Button>

            <Button
                pill
                color="primary"
                className="flex lg:hidden w-2/5 h-12 mr-auto"
            >
              <span className="text-xl font-semibold">Entrar</span>
            </Button>

            <img
                className="block lg:hidden absolute -bottom-16 -left-12  max-w-[135px] rotate-180"
                id="arrow-up-twirl"
                src="/assets/images/arrows/arrow_down_twirl.png"
                alt="Arrow down"
            />
          </div>
          <div className="hidden lg:block w-full px-4">
            <Button pill className="w-full h-12" color="white">
              <img
                className="w-8 h-auto mr-3"
                src="/assets/images/logos/google_logo.svg"
                alt="Google Logo"
              />
              &nbsp;<span className="text-xl font-semibold">Registar com a Google</span>
            </Button>
          </div>
        </div>
      </div>

      <img
          className="hero-star absolute -bottom-48 -left-12 lg:-bottom-56 lg:left-48 max-w-[180px] lg:max-w-[280px]"
          src="/assets/images/other/star.png"
          alt="Star"
      />
    </div>
  );
};
