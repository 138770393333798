import { Customer } from "../../../api";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import "./customer-map-flip-card.scss";

type CustomerMapPopupProps = {
  customer: Customer;
  visible?: boolean;
  className: string;
};

export const CustomerMapPopup = ({
  customer,
  visible = true,
  className = "",
}: CustomerMapPopupProps) => {
  const navigate = useNavigate();
  const flipCard = (event: any) => {
    event.currentTarget.closest(".flippable-card").classList.add("flipped");
    event.currentTarget.blur();
  };

  const unflipCard = (event: any) => {
    event.currentTarget.closest(".flippable-card").classList.remove("flipped");
    event.currentTarget.blur();
  };

  const handleToolClick = () => navigate("/ferramentas");
  console.log(customer);
  return (
    <div
      className={`absolute h-[430px] w-[270px] left-0 top-1/2 -translate-y-1/2 hidden lg:block ${
        visible ? "" : "hidden"
      } ${className}`}
    >
      <div className="relative w-full h-full flippable-card rounded-card">
        <div className="flex flex-col card-front absolute w-full h-full rounded-card overflow-hidden p-7 pb-[4.7rem] pt-4 gap-4">
          <img
            className="customer-logo max-h-[68px] mx-auto -mb-2"
            src={customer?.logo ?? ""}
            alt={customer?.name ?? ""}
          />
          <div className="font-title text-xl font-semibold">
            {customer?.shortName ?? ""}
          </div>
          <div className="max-h-[124px] overflow-x-scroll text-sm leading-[18px] pointer-events-auto">
            {customer?.description ?? ""}
          </div>
          <div className="flex flex-row mt-auto">
            {customer?.metrics?.map((metric, i, metrics) => (
              <div
                key={`${customer.slug}-${metric.name}-metric`}
                className={`customer-metric flex flex-col w-1/${metrics.length}`}
              >
                <span className="text-primary-500 font-semibold font-title text-xl">
                  {metric.data}
                </span>
                <span className="font-medium font-body text-base leading-18">
                  {metric.description}
                </span>
              </div>
            ))}
          </div>
          <Button
            pill
            id="flip"
            color="white"
            className="absolute right-5 bottom-5 bg-white rounded-full shadow-button w-12 h-12 flex flex-col justify-center cursor-pointer border-primary-200 border-solid"
            onClick={flipCard}
          >
            <svg
              className="w-8 h-8 m-auto text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </Button>
        </div>
        <div className="absolute card-back border-0 w-full h-full bg-white rounded-card overflow-hidden p-7 text-sm">
          <div className="flex flex-col gap-5">
            <span className="text-base">
              {customer?.shortName ?? ""} está a utilizar as seguintes
              ferramentas:
            </span>
            <div className="flex flex-col gap-5">
              {(customer?.products as Array<string>)?.map((product) => (
                <span
                  key={`${customer.slug}-${product}-tool`}
                  className="customer-tool rounded-full bg-gray-100 hover:bg-gray-200 cursor-pointer py-2 text-center align-middle"
                  onClick={handleToolClick}
                >
                  {product}
                </span>
              )) ?? ""}
            </div>
          </div>
          <Button
            pill
            id="flip"
            color="white"
            className="absolute right-5 bottom-5 bg-white rounded-full shadow-button w-12 h-12 flex flex-col justify-center cursor-pointer"
            onClick={unflipCard}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
};
