import {ImpactDisplayer} from "../../molecules/impact-displayer/impact-displayer";
import "./schools-impact.scss"

export const SchoolsImpact = () => {
    return (
        <div className="impact flex flex-col items-center mt-40">
            <div className="flex">
                <span className="text-2xl lg:text-2.5xl font-semibold font-title text-secondary-500 text-center px-8 lg:px-0"
                >Transformamos jovens e crianças<br className="hidden lg:block"/> em agentes de cidadania</span>
            </div>

            <div className="flex flex-wrap justify-center mt-8">
                <ImpactDisplayer
                    imgSrc="/assets/images/impact/schools_impact_1.png"
                    title="94,12%"
                    description="Autonomia"
                ></ImpactDisplayer>

                <div className="w-full lg:w-auto mx-0 lg:mx-8">
                    <ImpactDisplayer
                        imgSrc="/assets/images/impact/schools_impact_2.png"
                        title="95,59%"
                        description="Empatia"
                    ></ImpactDisplayer>
                </div>

                <ImpactDisplayer
                    imgSrc="/assets/images/impact/schools_impact_3.png"
                    title="97,06%"
                    description="Trabalho colaborativo"
                ></ImpactDisplayer>
            </div>

            <div className="flex flex-wrap justify-center mt-8">
                <div className="w-full lg:w-auto mr-0 lg:mr-4">
                    <ImpactDisplayer
                        imgSrc="/assets/images/impact/schools_impact_4.png"
                        title="95,6%"
                        description="Criatividade"
                    ></ImpactDisplayer>
                </div>

                <div className="w-full lg:w-auto ml-0 lg:ml-4">
                    <ImpactDisplayer
                        imgSrc="/assets/images/impact/schools_impact_5.png"
                        title="95,5%"
                        description="Pensamento Crítico e Analítico"
                    ></ImpactDisplayer>
                </div>
            </div>

            <div className="flex flex-col mt-5">
                <span
                    className="text-sm text-center px-4 lg:px-0"
                >% turmas em que houve progressão nas competências<br className="hidden lg:block"/> pessoais, sociais e emocionais acima referidas.
                </span>
            </div>

            <div className="flex mt-20">
                <span className="text-2xl lg:text-2.5xl font-semibold font-title text-secondary-500 text-center px-8 lg:px-0"
                >Aumentamos a literacia<br/>democrática dos jovens e crianças:</span>
            </div>

            <div className="flex flex-wrap justify-center mt-8">
                <ImpactDisplayer
                    imgSrc="/assets/images/impact/schools_impact_6.png"
                    title="95,59%"
                    description="Importância do voto"
                ></ImpactDisplayer>

                <div className="w-full lg:w-auto mx-0 lg:mx-8">
                    <ImpactDisplayer
                        imgSrc="/assets/images/impact/schools_impact_7.png"
                        title="92,65%"
                        description="Conhecimento sobre canais de participação"
                    ></ImpactDisplayer>
                </div>

                <ImpactDisplayer
                    imgSrc="/assets/images/impact/schools_impact_8.png"
                    title="100%"
                    description="Criar soluções para problemas sociais"
                ></ImpactDisplayer>
            </div>

            <div className="flex flex-wrap justify-center mt-8">
                <div className="w-full lg:w-auto mr-0 lg:mr-4">
                    <ImpactDisplayer
                        imgSrc="/assets/images/impact/schools_impact_9.png"
                        title="89,71%"
                        description="Implementar soluções criadas"
                    ></ImpactDisplayer>
                </div>

                <div className="w-full lg:w-auto ml-0 lg:ml-4">
                    <ImpactDisplayer
                        imgSrc="/assets/images/impact/schools_impact_10.png"
                        title="97,06%"
                        description="Apropriação e responsabilização sobre o espaço escolar"
                    ></ImpactDisplayer>
                </div>
            </div>

            <div className="flex flex-col mt-5">
                <span
                    className="text-sm text-center px-4 lg:px-0"
                >% turmas em que houve progressão nas competências<br className="hidden lg:block"/>pessoais, sociais e emocionais acima referidas.
                </span>
            </div>
        </div>
    );
};
