import { Flowbite } from "flowbite-react";
import { useEffect, useState } from "react";
import Router from "./routes";
import theme from "./theme";
import { RouterProvider } from "react-router-dom";

const loadFonts = async (fonts: string[]) => {
  await document.fonts.ready;
  for (let f of fonts) {
    await document.fonts.load(`12px ${f}`);
  }
};

const App = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    loadFonts(["Unbounded", "Inter"]).then(() => setIsReady(true));
    setTimeout(() => setIsReady(true), 1500);
  }, [setIsReady]);

  return (
    <Flowbite theme={{ theme }}>
      {isReady && <RouterProvider router={Router()} />}
    </Flowbite>
  );
};

export default App;
