import TestimonyCard from "../../molecules/testimony-card";

export const Feedback = ({schoolsPage = false}) => {
    return (
        <div className="flex flex-col items-center mt-20 relative z-10">
            <div className="relative flex">
                <img
                    src="/assets/images/other/rocketship.png"
                    alt=""
                    className={"absolute -left-16 -top-24 lg:-left-64 lg:-top-20 max-w-[240px] " + (schoolsPage ? 'hidden' : '')}
                />
                <span className="mx-auto text-2.5xl font-semibold font-title text-secondary-500 text-center px-20 lg:px-0">
                  O que dizem sobre nós
                </span>
                <img
                    src="/assets/images/other/white_star.svg"
                    alt=""
                    className={"absolute " + (schoolsPage ? '-left-40 -top-12' : 'right-12 bottom-12 lg:-right-24 lg:-bottom-5')}
                />

                <img
                    src="/assets/images/other/red_baloon_lg.png"
                    alt=""
                    className={"absolute -right-56 bottom-2 max-w-[158px] " + (schoolsPage ? '' : 'hidden')}
                />
            </div>

            <div className="grid grif-cols-1 lg:grid-cols-3 gap-8 mt-16">
                <div className="grid gap-8 px-12 lg:px-0">
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade"',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                                video:
                                    "/assets/tram.mp4",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                </div>
                <div className="grid gap-8 px-12 lg:px-0">
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000"
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                                picture:
                                    "https://d150u0abw3r906.cloudfront.net/wp-content/uploads/2021/10/image2-2-1024x649.png",
                            }}
                        ></TestimonyCard>
                    </div>
                </div>
                <div className="grid gap-8 px-12 lg:px-0">
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                    <div>
                        <TestimonyCard
                            testimony={{
                                body: '"A MyPolis abre a porta para os cidadãos e cidadãs que querem melhorar as suas cidades, ligando-os a políticos e promovendo a colaboração na comunidade. A MyPolis está a construir uma nova Polis digital, onde os millennials participam através dos seus telemóveis de uma forma divertida, simples e intuitiva."',
                                firstName: "Bruno",
                                lastName: "Martins",
                                personRole: "None of your business",
                                profilePicture:
                                    "https://i.seadn.io/gae/2hDpuTi-0AMKvoZJGd-yKWvK4tKdQr_kLIpB_qSeMau2TNGCNidAosMEvrEXFO9G6tmlFlPQplpwiqirgrIPWnCKMvElaYgI-HiVvXc?auto=format&dpr=1&w=1000",
                            }}
                        ></TestimonyCard>
                    </div>
                </div>
            </div>
        </div>
    );
};
