import {create} from "zustand";
import {Tool} from "../../../api";
import {ToolCard} from "../../molecules/tool-card/tool-card";
import {ToolModal} from "../../molecules/tool-modal/tool-modal";

interface ToolsState {
    openModal: boolean;
    activeTool: Tool | undefined;
    setOpenModal: (modalState: boolean) => void;
    setActiveTool: (tool: Tool | undefined) => void;
}

const useState = create<ToolsState>()((set) => ({
    openModal: false,
    activeTool: undefined,
    setOpenModal: (modalState: boolean) =>
        set((state: ToolsState) => ({
            openModal: modalState,
        })),
    setActiveTool: (tool: Tool | undefined) =>
        set((state: ToolsState) => ({
            activeTool: tool,
        })),
}));

export const Tools = ({schoolsPage = false}) => {
    const {openModal, setOpenModal, activeTool, setActiveTool} = useState();

    return (
        <div className="flex flex-col items-center">
            <div className="flex flex-col items-center gap-y-6">
                <div className="m-auto mt-24 relative">
                    <span
                        className="flex text-2.5xl font-semibold font-title relative
                        px-4 whitespace-break-spaces text-center lg:px-0 mx-auto">
                        As nossas ferramentas
                    <img
                        src="/assets/images/lines/spiral.svg"
                        alt=""
                        className={
                            "absolute -top-24 -left-4 lg:-top-16 lg:-left-72 -rotate-45 " +
                            (schoolsPage ? "hidden" : "")
                        }
                    />
                    </span>

                    <img
                        src="/assets/images/other/schools_tools.png"
                        alt=""
                        className={
                            "absolute top-[50%] left-[50%] " +
                            "-translate-x-[50%] -translate-y-[70%] max-w-[605px] " +
                            (schoolsPage ? "hidden lg:block" : "hidden")
                        }
                    />

                    <img
                        src="/assets/images/other/schools_tools_mobile.png"
                        alt=""
                        className={
                            "absolute top-[50%] left-[50%] " +
                            "-translate-x-[50%] -translate-y-[50%] max-w-[300px] " +
                            (schoolsPage ? "block lg:hidden" : "hidden")
                        }
                    />
                </div>

                <div className="flex w-[100vw] px-3 overflow-x-scroll mt-10 lg:mt-0">
                    <div className="flex mx-auto">
                        <ToolCard
                            tool={{
                                title: "Exploradores da Cidadania",
                                description:
                                    "Jogo de tabuleiro para dinamizar sessões participativas nas salas de " +
                                    "aulas de 1º ciclo, explorando de forma prática várias dimensões da participação " +
                                    "cidadã. Passeia pela estrada deste jogo e melhora o teu território!",
                                url: "/assets/images/tools/exploradores_cidadania.png",
                            }}
                            activeTool={activeTool}
                            activeToolHandler={setActiveTool}
                            showMoreClickHandler={setOpenModal}
                        />

                        <ToolCard
                            tool={{
                                title: "Mansão Civitas",
                                description:
                                    "Escape room digital de literacia democrática. De sala em sala os alunos " +
                                    "vão responder a desafios, resolver charadas e descobrir mais sobre democracia e " +
                                    "cidadania. Atenção às pistas do gato Cid!",
                            }}
                            activeTool={activeTool}
                            activeToolHandler={setActiveTool}
                            showMoreClickHandler={setOpenModal}
                        />

                        <ToolCard
                            tool={{
                                title: "Agentes da Cidadania",
                                description:
                                    "Jogo para o 2º e 3º ciclos, no qual alunos e alunas assumem o papel de " +
                                    "'Agentes da Cidadania', cumprindo desafios e missões cívicas. Esta jornada de " +
                                    "participação resulta na Assembleia de Transformadores Sociais.",
                            }}
                            activeTool={activeTool}
                            activeToolHandler={setActiveTool}
                            showMoreClickHandler={setOpenModal}
                        />

                        <ToolCard
                            tool={{
                                title: "Conselheiros da Cidadania",
                                description: "Descrição",
                            }}
                            activeTool={activeTool}
                            activeToolHandler={setActiveTool}
                            showMoreClickHandler={setOpenModal}
                        />

                        <ToolModal
                            modalCloseHandler={setOpenModal}
                            openModal={openModal}
                            tool={activeTool as Tool}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
