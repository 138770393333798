import { Avatar } from "flowbite-react";

type CardPictureOptions = {
  src: string;
  alt: string;
  name: string;
  bottomText: string;
  borderRadius?: number | string;
};

const CardProfilePicture = ({
  src,
  alt,
  name,
  bottomText,
  borderRadius = "50%",
}: CardPictureOptions) => (
  <Avatar
    rounded
    img={({ className, ...props }) => (
      <img
        alt={alt ?? ""}
        referrerPolicy="no-referrer"
        src={src ?? ""}
        className={className + " object-cover h-[65px] w-[65px]"}
        {...props}
        sizes="lg"
      />
    )}
  >
    <div className="space-y-1 font-medium dark:text-white">
      <div className="font-title text-xl">{name}</div>
      <div className="text-sm text-gray-500 dark:text-gray-400 italic">
        {bottomText}
      </div>
    </div>
  </Avatar>
);

export default CardProfilePicture;
