// import { PartnerCarousel } from "../../molecules/partner-carousel";

export const Tools = () => {
  return (
    <div id="page" className="tools">
    </div>
  );
};

export default Tools;
