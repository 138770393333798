import { ImpactDisplayer } from "../../molecules/impact-displayer/impact-displayer";
import "./impact.css";

export const Impact = ({ titleVisible = true }) => {
  return (
    <div className="impact flex flex-col items-center mt-36 lg:mt-24">
      {titleVisible && (
        <div className="relative flex">
          <img src="/assets/images/lines/curve.svg" alt="Curve" className="absolute -top-6 -left-6 rotate-45" />
          <span className="text-2xl font-semibold font-title text-secondary-500 my-auto text-center">
            O nosso Impacto
          </span>

          <img
            id="impact-balloon"
            src="/assets/images/other/yellow_balloon.png"
            alt="Balloon"
            className="absolute -top-16 right-5 lg:-top-10 lg:-right-32"
          />
        </div>
      )}

      {titleVisible && (
        <span className="mx-auto my-5 text-base font-body text-center px-12 lg:px-0">
          Conhece as propostas criadas na MyPolis que já foram implementadas!
        </span>
      )}

      <div className="flex flex-wrap justify-center">
        <ImpactDisplayer
          imgSrc="/assets/images/impact/impact_1.svg"
          title="+ 25.000"
          description="Jovens Alcançados"
        ></ImpactDisplayer>

        <ImpactDisplayer
          imgSrc="/assets/images/impact/impact_3.svg"
          title="+ 4.000"
          description="Propostas transformadoras submetidas"
        ></ImpactDisplayer>

        <ImpactDisplayer
          imgSrc="/assets/images/impact/impact_4.svg"
          title="115"
          description="Assembleias Participativas realizadas"
        ></ImpactDisplayer>

        <ImpactDisplayer
          imgSrc="/assets/images/impact/impact_5.svg"
          title="+ 200"
          description="Propostas implementadas"
        ></ImpactDisplayer>

        <ImpactDisplayer
          imgSrc="/assets/images/impact/impact_6.svg"
          title="86%*"
          description="dos alunos avaliam projeto com nota 7 ou superior"
        ></ImpactDisplayer>

        <ImpactDisplayer
          imgSrc="/assets/images/impact/impact_7.svg"
          title="99%**"
          description="dos professores avaliam o projeto com nota 7 ou superior"
        ></ImpactDisplayer>

        <ImpactDisplayer
          imgSrc="/assets/images/impact/impact_2.svg"
          title="26"
          description="localidades onde desenvolvemos processos participativos"
        ></ImpactDisplayer>
      </div>

      <div className="flex flex-col mt-5">
        <span className="text-sm text-center">
          *Avaliação pelo ISCTE, no âmbito do fundo +Plus da SCML.
        </span>
        <span className="text-sm text-center">
          **Professores participantes no ano letivo 2021-22.
        </span>
      </div>
    </div>
  );
};
