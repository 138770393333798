import { Card } from "flowbite-react";
import { Testimony } from "../../api";
import CardBody from "../atoms/card/card-body";
import CardProfilePicture from "../atoms/card/card-profile-picture";
import CardVideo from "../atoms/card/card-video";
import { CardPicture } from "../atoms/card/card-picture";

type TestimonyCardOptions = {
  testimony: Testimony;
  className?: string;
};

const TestimonyCard = ({ testimony, className = "" }: TestimonyCardOptions) => {
  const body = testimony.video ? (
    <CardVideo src={testimony.video}></CardVideo>
  ) : (
    <CardBody text={testimony.body ?? "Erro"}></CardBody>
  );
  const image = testimony.picture ? (
    <CardPicture src={testimony.picture} />
  ) : (
    ""
  );
  return (
    <Card
      className={
        "break-inside-avoid-column max-w-full min-w-[330px] lg:max-w-[280px] lg:min-w-[280px] " +
          "card testimony-card inline-block h-full p-8 max-h-max m-0 " +
        className
      }
    >
      <CardProfilePicture
        src={testimony.profilePicture ?? ""}
        alt="Bababui"
        name={`${testimony.firstName} ${testimony.lastName}`}
        bottomText={testimony.personRole ?? ""}
      />
      {body}
      {image}
    </Card>
  );
};

export default TestimonyCard;
