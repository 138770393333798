import {TextFigure} from "../../molecules/text-figure/text-figure";

type impactListType = {
    title: string;
    text: string;
    image: string;
    description: string;
}

const impactList: Array<impactListType> = [
    {
        title: "Aumento da participação e envolvimento cívico dos jovens",
        text: "A MyPolis está a reacender o interesse dos jovens participantes em ser parte da solução para os " +
            "problemas das suas comunidades. Nos últimos anos, procurámos criar ferramentas quetornem a participação " +
            "cívica mais simples, inclusiva e divertida e que abram a porta ao diálogo com representantes políticos " +
            "para todos/as. Mais de 25.000 jovens já participaram através da MyPolis em 23 autarquias.",
        image: "/assets/images/impact/oeiras_1.png",
        description: "Alunos em ação na Escola Secundária Luís Freitas Branco, em Oeiras."
    },
    {
        title: "Democracia na sala de aula",
        text: "Trabalhando em parceria com Professores/as, desenvolvemos nas crianças e jovens competências pessoais e " +
            "sociais como a empatia, a criatividade ou o pensamento crítico, em centenas de salas de aula de todo o " +
            "país. Aumentamos a literacia democrática das crianças e dos jovens, fortalecendo o seu conhecimento sobre " +
            "canais de participação e sensibilizando para a importância do voto, por exemplo. Esta intervenção em " +
            "contexto escolar é considerada muito positiva por 99% dos Professores/as que trabalharam connosco " +
            "(7 ou superior numa escala 1-10).",
        image: "/assets/images/impact/oeiras_2.png",
        description: "Participação dos alunos/as em sala de aula de Oeiras."
    },
    {
        title: "Assembleias de Transformadores Sociais",
        text: "A MyPolis tem promovido uma linha directa de comunicação entre jovens e representantes em assembleias " +
            "de transformadores sociais. Já realizámos mais de 115 Assembleias participativas. Apesar da ideia " +
            "instalada de que os jovens não se sentem atraídos pelas Assembleias tradicionais, como Assembleias " +
            "Municipais, 91,4% dos jovens referem que gostam de conhecer o seu representante político nestas " +
            "assembleias promovidas através da MyPolis.",
        image: "/assets/images/impact/fundao.png",
        description: "Assembleia de Transformadores Sociais na Câmara Municipal do Fundão."
    },
    {
        title: "Transformação do território e das comunidades",
        text: "Jovens, professores e autarquias trabalham de forma colaborativa para gerar mudanças positivas no " +
            "território e nas comunidades. Plantação de árvores, atendimento inclusivo para invisuais, uma papelaria " +
            "solidária, hortas nas escolas, mais paragens de autocarro, uma feira solidária que recolheu meia " +
            "tonelada de alimentos e combate ao bullying nas escolas. Já demos origem a mais de 200 projectos com " +
            "impacto concreto nos territórios e comunidades MyPolis e há cada vez mais propostas a ser implementadas " +
            "em vários pontos do país.",
        image: "/assets/images/impact/lousa.png",
        description: "Implementação de proposta de remoção de espécies invasoras e plantação de árvores na Serra da " +
            "Lousã, pelo 6ºA do Agrupamento de Escolas da Lousã em parceria com associação “Activar” e CM Lousã."
    }
];

export const ImpactList = () => {
    return (
        <div className="impact-list flex flex-col items-center mt-20">
            <div className="relative flex mb-8">
                <img
                    src="/assets/images/other/white_star.svg"
                    alt="Curve"
                    className="absolute -bottom-6 -left-8 brightness-0 max-w-[35px]"
                />
                <span className="text-2xl font-semibold font-title text-secondary-500 my-auto">O nosso Impacto</span>
                <img
                    src="/assets/images/lines/curve.svg"
                    alt="Curve"
                    className="absolute -top-6 -right-6 rotate-[115deg]"
                />
            </div>

            {impactList.map((el, i) => {
                return (
                    <TextFigure
                        title={el.title}
                        text={el.text}
                        maxLength="max-w-[85%]"
                        srcImage={el.image}
                        description={el.description}
                        reverse={i % 2 !== 0}
                        key={i}
                    />
                );
            })}
        </div>
    );
};
