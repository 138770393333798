import {HistoryList} from "../../../organisms/history-list/history-list";
import {ContactUs} from "../../../organisms/contact-us/contact-us";

export const History = () => {
    return (
        <div id="page" className="history">
            <HistoryList/>
            <br/>
            <ContactUs className={"history"} />
        </div>
    );
};

export default History;
