import {Button} from "flowbite-react";
import {Link} from "react-router-dom";
import "./hero-schools.scss";

export const HeroSchools = ({className}: any) => {
    return (
        <div
            className={
                className +
                " hero-schools gap-x-2 flex flex-col lg:flex-row justify-around w-full pt-10 -mb-12 lg:-mb-24"
            }
        >
            <div className="flex flex-col items-center justify-around w-full lg:w-1/2 gap-y-10">
                <div
                    className="flex flex-col lg:items-start justify-center text-3xl lg:text-5xl
                                text-secondary-500 font-title gap-y-2 lg:-rotate-5 w-full px-4"
                >
                    <div className="flex flex-col w-fit mx-auto">
                        <span className="px-4 w-fit">Transformamos</span>
                        <span className="mt-2 px-4 py-2 bg-red-500 lg:bg-primary-500 text-white rounded-full w-fit">
                            as salas de aula
                        </span>
                        <span className="mt-2 px-4 w-fit">em Academias</span>
                        <div className="flex flex-row mt-2 px-4">
                            <span className="py-2">de&nbsp;</span>
                            <span className="px-2 lg:px-4 py-2 w-max bg-primary-500 text-white">
                                participação
                            </span>
                        </div>
                    </div>

                    <img
                        className="block lg:hidden w-full mx-auto mt-8"
                        src="/assets/images/other/phone_mobile.png"
                        alt="Loud Speaker"
                    />

                    <div className="text-base lg:text-xl text-primary-900/50 mt-2 px-4">
                        99% dos professores dão uma
                        <br/>
                        avaliação muito positiva ao programa MyPolis nas escolas.
                    </div>
                </div>
                <div className="flex flex-col w-full relative">
                    <div className="w-full px-4">
                        <Button
                            pill
                            as={Link}
                            target="_blank"
                            className="w-full h-12"
                            color="secondary"
                            to="https://escolas.mypolis.eu"
                        >
              <span className="text-xl font-semibold">
                Entrar no site dos professores
              </span>
                        </Button>
                    </div>

                    <img
                        className="rotate-180 w-1/4 absolute left-32"
                        id="arrow-down-twirl"
                        src="/assets/images/arrows/arrow_down_twirl.png"
                        alt="Arrow down"
                    />
                </div>
            </div>

            <div className="hidden lg:block w-full lg:w-1/2">
                <img
                    className="w-4/5 mx-auto mt-8"
                    src="/assets/images/other/phone.png"
                    alt="Loud Speaker"
                />
            </div>
        </div>
    );
};
