import { PartnerCarousel } from "../../molecules/partner-carousel/partner-carousel";
import { CustomerMap } from "../../organisms/customer-map/customer-map";
import { ContactUs } from "../../organisms/contact-us/contact-us";
import { Feedback } from "../../organisms/feedback/feedback";
import { Hero } from "../../organisms/hero/hero";
import { ImplementedProposals } from "../../organisms/implemented-proposals/implemented-proposals";
import { Metrics } from "../../organisms/metrics/metrics";

const LandingPage = () => {
  return (
    <div id="page" className="landing-page">
      <Hero />
      <PartnerCarousel />
      <br />
      <Metrics />
      <br />
      <CustomerMap />
      <br />
      <ImplementedProposals />
      <br />
      <Feedback />
      <br />
      <ContactUs className={"landing"} />
    </div>
  );
};

export default LandingPage;
