import {TeamList} from "../../../organisms/team-list/team-list";
import {ContactUs} from "../../../organisms/contact-us/contact-us";

export const Team = () => {
    return (
        <div id="page" className="team">
            <TeamList/>
            <ContactUs className={"team"}/>
        </div>
    );
};

export default Team;
