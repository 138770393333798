import { useState } from "react";
import { SlideGesture } from "../gestures/slide-gesture";
type ImageGridDisplayProps = {
  items?: Array<{ src: string; alt: string }>;
};
export const ImageGridDisplay = ({ items = [] }: ImageGridDisplayProps) => {
  const [slideGestureActive, setSlideGestureActive] = useState(true);

  const touchHandler = () => {
    setSlideGestureActive(false);
  };

  return (
    <div className="relative" onTouchMove={touchHandler}>
      <div className="relative flex flex-row max-w-[310px] overflow-y-hidden w-[310px] h-[300px] mx-auto shadow-button bg-white rounded-card lg:overflow-x-visible">
        <div className="flex flex-row flex-wrap min-w-[250%] h-full px-4 py-3 justify-start">
          {items.map((item) => (
            <div className="flex w-30 h-20 px-3 py-1">
              <img
                className="max-h-full m-auto"
                src={item.src}
                alt={item.alt}
              ></img>
            </div>
          ))}
        </div>
      </div>
      <SlideGesture
        active={slideGestureActive}
        className="absolute top-0 w-14 h-20"
      />
    </div>
  );
};
