/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Product = {
    id?: number;
    banner?: string;
    media_set?: Array<string>;
    name?: string;
    tag?: Array<{
        name?: string;
        order?: number;
    }>;
    description?: string;
    objectives?: string;
    target_audience?: string;
    format?: Product.format;
    results?: string;
    customeres?: Array<string>;
};

export namespace Product {

    export enum format {
        DIGITAL = 'Digital',
        ANALOGICO = 'Analogico',
    }


}

