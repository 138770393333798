export const Backoffice = () => {

    return (
        <div className="flex flex-col items-center">
            <div className="flex flex-col items-center gap-y-6">
                <span className="m-auto mt-24 text-2.5xl font-semibold font-title relative">
                    Backoffice
                    <img
                        src="/assets/images/lines/hard_angles.png"
                        alt="Lines"
                        className="absolute -top-12 -right-24"
                    />
                </span>
                <div className="flex flex-col-reverse lg:flex-col">
                    <div className="flex flex-row">
                        <span className="m-auto text-lg font-body text-center px-8 lg:px-0"
                        >Com o backoffice MyPolis pode acompanhar a participação digital dos jovens,
                            <br className="hidden lg:block"/> dar feedback em tempo real e ter acesso a estatísticas de participação.
                        </span>
                    </div>
                    <div className="flex mx-auto my-6">
                        <img
                            src="/assets/images/other/mock_plat.png"
                            alt="Backoffice"
                            className=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
