type GeometryType =
  | "Point"
  | "MultiPoint"
  | "LineString"
  | "MultiLineString"
  | "Polygon"
  | "MultiPolygon"
  | "GeometryCollection";

type LngLat = Array<number>;

type GeoJsonFeatureCollection = {
  type: "FeatureCollection";
  features: GeoJsonFeature[];
};

type GeoJsonFeature = {
  type: "Feature";
  id: string;
  geometry: {
    type: GeometryType;
    coordinates: LngLat;
  };
  properties: any;
};

type GeoJsonObject = GeoJsonFeature | GeoJsonFeatureCollection;

export const convertObjectToGeoJson = (
  data: any,
  type: GeometryType = "Point"
): GeoJsonObject => {
  if (!data || typeof data === "undefined" || data === null)
    throw new Error("Data object cannot be empty");

  let geoJson: GeoJsonObject;

  if (Array.isArray(data)) {
    if (!data[0]?.location)
      throw new Error("The items must contain 'Location' property.");

    geoJson = {
      type: "FeatureCollection",
      features: data.map((item) => {
        return {
          type: "Feature",
          geometry: {
            type,
            coordinates: [item.location.longitude, item.location.latitude],
          },
          id: item.id,
          properties: { data: JSON.stringify(item) },
        };
      }),
    };
  } else {
    if (!data?.location)
      throw new Error("The object must contain 'Location' property.");

    geoJson = {
      type: "Feature",
      id: data.id,
      geometry: {
        type,
        coordinates: [data.location.longitude, data.location.latitude],
      },
      properties: { data: JSON.stringify(data) },
    };
  }

  return geoJson;
};
