import { Button, Card } from "flowbite-react";
import CardSubtitle from "../../atoms/card/card-subtitle";
import CardTitle from "../../atoms/card/card-title";
import "./tool-card.css";
import { Tool } from "../../../api";

type ToolCardOptions = {
  tool: Tool;
  showMoreClickHandler: React.Dispatch<any>;
  activeTool: Tool | undefined;
  activeToolHandler: React.Dispatch<any>;
};

export const ToolCard = ({
  tool,
  showMoreClickHandler = () => {},
  activeTool,
  activeToolHandler = () => {},
}: ToolCardOptions) => {
  return (
    <Card className="w-64 h-[430px] card tool-card">
      <div className="flex flex-col h-full w-full p-5">
        <div className="image w-full h-auto grow rounded-card" style={{background: `url(${(tool?.url)})`}}></div>
        <div className="body-container mt-4 flex flex-col justify-center w-full">
          <div className="title w-full mb-2">
            <CardTitle
              className="leading-6"
              text={tool.title ?? ""}
            ></CardTitle>
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full">
              <CardSubtitle text={tool.description ?? ""}></CardSubtitle>
            </div>
            <div className="button w-full mx-auto mt-4">
              <Button
                pill
                color="primary"
                className="text-base font-semibold h-10 w-full"
                onClick={() => {
                  activeToolHandler(tool);
                  showMoreClickHandler(true);
                }}
              >
                Mais Informações
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
