import {RouteObject} from "react-router-dom";
import landingPageRoutes from "./home";
import {Layout} from "../templates/layout";
import schoolsPageRoutes from "./schools";
import aboutUsPageRoutes from "./about-us";
import municipalitiesPageRoutes from "./municipalities";
import participatePageRoutes from "./how-to-participate";

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Layout/>,
        children: [
            ...landingPageRoutes,
            ...municipalitiesPageRoutes,
            ...schoolsPageRoutes,
            ...aboutUsPageRoutes,
            ...participatePageRoutes
        ],
    },
];

export default routes;
