export const Figure = ({srcImage, description, maxWidth = 'max-w-[350px]'}:
                           { srcImage: string, description: string, maxWidth?: string }) => {
    return (
        <div className="flex flex-col w-full lg:w-auto">
            <img src={srcImage} alt="Impact"
                 className={"mx-auto rounded-[24px] lg:mx-0 " + maxWidth}/>

            <span className="text-xs font-medium italic text-center mt-4 px-10"
                  dangerouslySetInnerHTML={{__html: description}}
            ></span>
        </div>
    );
};
