import {Button, Modal} from "flowbite-react";
import {ImplementedProposal} from "../../../api";
import React from "react";

type ProposalModalProps = {
    proposal: ImplementedProposal;
    openModal: boolean;
    modalCloseHandler: React.Dispatch<any>;
};

export const ProposalModal = ({proposal, openModal, modalCloseHandler}: ProposalModalProps) => {
    return (
        <Modal
            size="5xl"
            dismissible
            show={openModal}
            onClose={() => modalCloseHandler(false)}
        >
            <Modal.Body>
                <div className="w-full h-full flex flex-col py-8 relative">
                    <Button
                        pill
                        id="flip"
                        color="white"
                        className="absolute top-0 right-0 bg-white rounded-full shadow-button w-12 h-12
                        flex flex-col justify-center cursor-pointer border-primary-200 border-solid rotate-45"
                        onClick={() => modalCloseHandler(false)}
                    >
                        <svg
                            className="w-8 h-8 m-auto text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 18"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 1v16M1 9h16"
                            />
                        </svg>
                    </Button>
                    <span className="text-2xl font-title px-8 mb-8">{proposal?.title ?? "No title provided"}</span>
                    <div className="flex flex-row overflow-x-auto">
                        <img className="min-w-[500px]" src="./assets/images/other/card-test.svg" alt="Proposal"/>
                    </div>
                    <span className="text-lg p-8" dangerouslySetInnerHTML={{__html: (proposal?.body || '')}}></span>
                    <div className="w-1/3 border-b-secondary-500 border-0 border-b-2 mx-8 mb-8"></div>
                    <span className="text-xl font-title px-8 mb-4">Ficha técnica:</span>
                    <span className="text-base font-title px-8">Criado por:</span>
                    <span className="text-base px-8 mb-4">{proposal?.technicalSheet?.proposalCreators}.</span>
                    <span className="text-base font-title px-8">Implementado por:</span>
                    <span className="text-base px-8 mb-4">{proposal?.technicalSheet?.proposalImplementers}.</span>
                    <span className="text-base font-title px-8">Escola:</span>
                    <span className="text-base px-8 mb-4">{proposal?.technicalSheet?.school}.</span>
                    <span className="text-base font-title px-8">Turma:</span>
                    <span className="text-base px-8 mb-4">{proposal?.technicalSheet?.class}.</span>
                    <span className="text-base font-title px-8">Professor/a responsável:</span>
                    <span className="text-base px-8">{proposal?.technicalSheet?.accountableTeacher}.</span>
                </div>
            </Modal.Body>
        </Modal>
    );
};
