import {History, ImpactPage, Team, Tools} from "./sub-pages";

const aboutUsPageRoutes = [
  { path: "/sobre-nos/historia", element: <History /> },
  { path: "/sobre-nos/equipa", element: <Team /> },
  { path: "/sobre-nos/ferramentas", element: <Tools /> },
  { path: "/sobre-nos/impacto", element: <ImpactPage /> },
];

export default aboutUsPageRoutes;
