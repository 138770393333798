import { FlippableCard } from "../../molecules/flippable-card/flippable-card";

export const Metrics = ({ className = "" }: any) => {
  let cards = undefined;

  if (className === "schools") {
    cards = (
      <div className="flex flex-row flex-wrap items-center justify-center w-full mx-auto cards perspective gap-[50px]">
        <FlippableCard
          imgSrc="/assets/images/cards/schools_card_1.png"
          cardTitle="Educação para a Cidadania"
          buttons={true}
        >
          Transformamos as salas de aula
          <br />
          em academias de participação.
          <br />
          <br />
          Tornamos a Educação para a
          <br />
          Cidadania <b>mais simples</b> para os
          <br />
          Professores/as e <b>divertida</b> para
          <br />
          os alunos/as através das
          <br />
          ferramentas MyPolis.
          <br />
          <br />
          Damos formação, fornecemos
          <br />
          materiais pedagógicos e
          <br />
          apoiamos os Professores
          <br />
          de Cidadania.
        </FlippableCard>

        <FlippableCard
          imgSrc="/assets/images/cards/schools_card_2.png"
          cardTitle="Gamificação"
          buttons={true}
        >
          Gamificamos os processos
          <br />
          participativos e colaborativos
          <br />
          de exploração e ação sobre
          <br />o território.
          <br />
          <br />
          Desenvolvemos jogos que
          <br />
          aumentam a participação
          <br />e promovem uma cidadania
          <br />
          ativa. Conheça melhor
          <br />
          <b>as nossas ferramentas</b>.
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/schools_card_3.png"
          cardTitle="Relação entre jovens e os seus Representantes Políticos/as"
          buttons={true}
        >
          Promovemos uma relação e
          <br />
          comunicação mais próxima
          <br />
          entre jovens e seus
          <br />
          representantes políticos,
          <br />
          através de Assembleias de
          <br />
          Transformadores Sociais.
          <div className="text-3xl font-title font-medium mt-2 mb-1">91.4%</div>
          dos jovens gostam de
          <br />
          conhecer o seu representante
          <br />
          político numa Assembleia.
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/schools_card_4.png"
          cardTitle="Ação na comunidade"
          buttons={true}
        >
          Potenciamos a concretização
          <br />
          de respostas sociais em
          <br />
          parceria com a comunidade.
          <br />
          <br />
          Os alunos/as desenvolvem
          <br />
          propostas transformadoras da
          <br />
          comunidade e implementam-nas,
          <br />
          muitas vezes com o apoio
          <br />
          do Município ou de
          <br />
          associações locais.
          <div className="text-3xl font-title font-medium mt-2 mb-1">+200</div>
          propostas já foram
          <br />
          implementadas.
        </FlippableCard>
      </div>
    );
  } else {
    cards = (
      <div className="flex flex-row flex-wrap items-center justify-center w-full mx-auto cards perspective gap-[30px] lg:flex-nowrap">
        <FlippableCard
          imgSrc="/assets/images/cards/what_we_do_card_1.svg"
          cardTitle="Democracia Digital"
          buttons={true}
        >
          <div className="text-3xl font-title font-medium mb-1">+3.500</div>
          propostas transformadoras
          <br />
          do território submetidas na
          <br />
          plataforma de democracia
          <br />
          participativa MyPolis.
          <br />
          <br />
          <div className="text-3xl font-title font-medium mt-2 mb-1">
            +40.000
          </div>
          votos digitais de forma
          <br />
          simples e intuitiva.
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/what_we_do_card_2.svg"
          cardTitle="Comunicação entre Jovens e Representantes
          Políticos/as"
          buttons={true}
        >
          <div className="text-3xl font-title font-medium mb-1">+150</div>
          Assembleias participativas
          <br />
          realizadas.
          <br />
          <br />
          <br />
          <br />
          <div className="text-3xl font-title font-medium mt-2 mb-1">
            +22.000
          </div>
          jovens envolvidos em diálogo
          <br />
          com <b>134</b> autarcas.
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/what_we_do_card_3.png"
          cardTitle="Democracia em Sala de Aula"
          buttons={true}
        >
          <div className="text-3xl font-title font-medium mb-1">95%*</div>
          Evolução dos alunos/as nas
          <br />
          competências-chave em 95%
          <br />
          das turmas participantes.
          <br />
          <span className="text-xs font-regular">
            *Avaliação pelo ISCTE, no âmbito
            <br />
            do fundo +Plus da SCML.
          </span>
          <br />
          <div className="text-3xl font-title font-medium mt-2 mb-1">99%**</div>
          dos professores avaliam
          <br />
          de forma muito positiva.
          <br />
          <span className="text-xs font-thin">
            **Professores participantes
            <br />
            no ano letivo 2021-22.
          </span>
        </FlippableCard>
      </div>
    );
  }

  return (
    <div
      className={
        className +
        " metrics lg:px-12 flex flex-col items-center justify-center mt-40 gap-y-6"
      }
    >
      <div className="relative flex flex-row">
        <span className="m-auto text-2.5xl font-semibold font-title">
          O que fazemos
        </span>
        <img
          src="/assets/images/arrows/arrow-left.svg"
          alt=""
          className="absolute -right-32 -top-5"
        />
      </div>
      <span className="m-auto text-base font-body text-center px-8 lg:px-0">
        Tornamos a participação cívica simples, divertida e inclusiva.
      </span>
      {cards}
    </div>
  );
};
